// date: 08/02/2023

import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        token: '',
        currentUser: null,
        isAuthenticated: false,
    },
    reducers: {
        authenticate(state, action) {
            state.token = action.payload.token;
            state.currentUser = action.payload.user;
            state.isAuthenticated = true;
            if (action.payload.rememberMe) {
                localStorage.setItem('username', action.payload.username);
                localStorage.setItem('password', action.payload.password);
                localStorage.setItem('token', action.payload.token);
            } else if (localStorage.getItem('username')) {
                localStorage.removeItem('username');
                localStorage.removeItem('password');
                localStorage.removeItem('token');
            }
        },
        logout(state) {
            state.token = null;
            state.currentUser = null;
            state.isAuthenticated = false;
            localStorage.removeItem('token');
        },
    },
});

export const authActions = authSlice.actions;

export default authSlice;
