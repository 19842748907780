/*
 ** @date: 08/02/2023
 ** @version: 1.0.0
 ** @author: argertboja@gmail.com
 ** @description: Login / Register screen
 ********************************
 ** @Expected Props:
 ** @Current version:
 ******** Logs user in
 ** @TODOs:
 ******** TODO: Register user
 ******** TODO: Forgot password functionality
 */

import React, { useState } from 'react';
import { Box, Grid, useTheme } from '@mui/material';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './styles.css';

// Images
import Logo from './../assets/icon-png.png';
import Image1 from './../assets/1.png';
import Image2 from './../assets/2.png';
import Image3 from './../assets/3.png';

// Custom components
import LoginForm from './screen_parts/LoginRegister/LoginForm';
import RegisterForm from './screen_parts/LoginRegister/RegisterForm';
import ForgotPasswordForm from './screen_parts/LoginRegister/ForgotPasswordForm';

function Login() {
    const theme = useTheme();

    // Images of carousel
    const images = [Image1, Image2, Image3];
    const [bgImage, setBgImage] = useState(images[0]);

    const [form, setForm] = useState('login');

    // Styles
    const classes = {
        container: {
            height: '100vh',
            width: '100vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        bluredBackgroundContainer: {
            position: 'absolute',
            backgroundImage: `url(${bgImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            filter: 'blur(40px)',
            zIndex: -1,
            inset: 0,
        },
        contentContainer: {
            height: window.innerWidth < 600 ? '90%' : '80%',
            width: window.innerWidth < 600 ? '90%' : '70%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            borderRadius: '20px',
            overflow: 'hidden',
            zIndex: 1,
            backgroundColor: 'white',
        },
        formContainer: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
        },
        logoContainer: {
            height: '30%',
            width: '100%',
            backgroundColor: theme.palette.primary.main,
            overflow: 'hidden',
        },
        logo: {
            marginBottom: '-20px',
            marginTop: '20px',
            width: '60px',
        },
        logoText: {
            backgroundImage: `linear-gradient(to right, white 0%, #568585 100%)`,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            display: 'inline-block',
        },
    };

    return (
        // Screen container
        <Box style={classes.container}>
            {/* Blured background container */}
            <Box style={classes.bluredBackgroundContainer} />
            {/* Content container grid */}
            <Grid style={classes.contentContainer} container spacing={0}>
                {/* Carousel for app preview */}
                <Grid
                    item
                    xs={12}
                    sm={6}
                    height='100%'
                    hidden={window.innerWidth < 600}
                >
                    <Carousel
                        showThumbs={false}
                        showStatus={false}
                        showArrows={false}
                        infiniteLoop={true}
                        autoPlay={true}
                        height='100%'
                        interval={8000}
                        onChange={(index, item) => {
                            setBgImage(images[index]);
                        }}
                    >
                        {images.map((image, index) => (
                            <img
                                key={index}
                                src={image}
                                height='100%'
                                alt={'image-' + index}
                            />
                        ))}
                    </Carousel>
                </Grid>
                {/* Login form container */}
                <Grid item xs={12} sm={6} style={classes.formContainer}>
                    {/* Logo container */}
                    <Box
                        style={{
                            ...classes.formContainer,
                            ...classes.logoContainer,
                        }}
                    >
                        <img src={Logo} style={classes.logo} alt='Logo' />
                        {/* Logo title */}
                        <h1 style={classes.logoText}>WorkWave</h1>
                    </Box>
                    {/* Login form container */}
                    {form === 'login' ? (
                        <LoginForm changeForm={setForm} />
                    ) : form === 'register' ? (
                        <RegisterForm changeForm={setForm} />
                    ) : form === 'forgotPassword' ? (
                        <ForgotPasswordForm changeForm={setForm} />
                    ) : null}
                </Grid>
            </Grid>
        </Box>
    );
}

export default Login;
