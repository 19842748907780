/*
 ** @date: 23/07/2023
 ** @version: 1.0.0
 ** @author: argertboja@gmail.com
 ** @description: This component creates a custom Cuntdown component
 ********************************
 ** @Expected Props:
 ******** props.initialTime: Int => Value of start of countdown
 ******** props.onFinish: Function => Function which is used to handle what happens when countdown finishes
 */
import React, { useState, useEffect } from 'react';

const Countdown = ({ initialTime, onFinish }) => {
    const [timeLeft, setTimeLeft] = useState(initialTime);

    const styles = {
        countdownContainer: {
            textAlign: 'center',
            marginTop: 5,
        },
        numberLabelContainer: {
            display: 'inline-block',
            marginRight: '10px',
            color: '#048282',
        },
        countdownText: {
            fontSize: '24px',
            margin: 0,
            backgroundColor: '#e9e9e9',
            padding: 8,
            borderRadius: 5,
            fontWeight: 'bold',
        },
        labelText: {
            fontSize: '12px',
            margin: 0,
        },
    };

    useEffect(() => {
        if (timeLeft > 0) {
            const interval = setInterval(() => {
                setTimeLeft((prevTime) => prevTime - 1);
            }, 1000);

            return () => clearInterval(interval);
        } else {
            onFinish();
        }
    }, [timeLeft, onFinish]);

    // Helper function to convert total seconds to minutes and seconds
    const formatTime = (totalSeconds) => {
        const minutes = Math.floor(totalSeconds / 60)
            .toString()
            .padStart(2, '0');
        const seconds = (totalSeconds % 60).toString().padStart(2, '0');
        return { minutes: minutes, seconds: seconds };
    };

    return (
        <div style={styles.countdownContainer}>
            <div style={styles.numberLabelContainer}>
                <p style={styles.countdownText}>
                    {formatTime(timeLeft).minutes}
                </p>
                <p style={styles.labelText}>MM</p>
            </div>
            <div style={styles.numberLabelContainer}>
                <p style={styles.countdownText}>
                    {formatTime(timeLeft).seconds}
                </p>
                <p style={styles.labelText}>SS</p>
            </div>
        </div>
    );
};

export default Countdown;
