import axios from 'axios';

const NAMESPACE = '/api/v1';

export async function signIn(input) {
    const response = await axios.post(NAMESPACE + '/users/login', {
        username: input.username,
        password: input.password,
    });
    return response.data;
}

export async function createUser(user) {
    const response = await axios.post(NAMESPACE + '/users/sign-up', {
        username: user.username,
        password: user.password,
        passwordConfirmation: user.confirmPassword,
        name: user.name,
        surname: user.surname,
        email: user.email,
    });
    return response.data;
}

export async function autoSignIn(token) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    const response = await axios.post(
        NAMESPACE + '/users/auto-login',
        null,
        config
    );
    return response.data;
}

export async function sendResetTokenToEmail(email) {
    const response = await axios.post(NAMESPACE + '/users/forgot-password', {
        email,
    });
    return response;
}

export async function resetPassword(token, password, confirmPassword) {
    const response = await axios.patch(
        `${NAMESPACE}/users/reset-password/${token}`,
        {
            password,
            passwordConfirmation: confirmPassword,
        }
    );
    return response;
}
