import React, { useState } from 'react';
import { Sidebar, Menu, MenuItem, useProSidebar } from 'react-pro-sidebar';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import ProfileImage from '../../assets/profile-picture.png';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../store/auth-slice';
import ConfirmationDialog from '../custom/CustomConfirmationDialog';

function Drawer() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { collapseSidebar, collapsed } = useProSidebar();
    const dispatch = useDispatch();
    const [logout, setLogout] = useState(false);
    const currentUser = useSelector((state) => state.auth.currentUser);

    const confirmationDialogModalHandler = () => {
        setLogout(!logout);
    };

    const logoutHandler = () => {
        dispatch(authActions.logout());
    };
    return (
        <div id="app" style={({ height: '100vh' }, { display: 'flex' })}>
            <Sidebar
                style={{ height: '100vh' }}
                backgroundColor={
                    theme.palette.mode === 'dark'
                        ? colors.primary[700]
                        : colors.primary[500]
                }
            >
                <Menu
                    menuItemStyles={{
                        button: ({ level, active, disabled }) => {
                            if (level === 0) {
                                return {
                                    backgroundColor: active
                                        ? '#fff'
                                        : undefined,
                                    fontSize: '16px !important',
                                    '&:hover': {
                                        backgroundColor:
                                            theme.palette.mode === 'dark'
                                                ? '#023434 !important'
                                                : 'white !important',
                                        fontWeight: 'bold !important',
                                    },
                                };
                            }
                        },
                    }}
                >
                    {!collapsed ? (
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            ml="15px"
                        >
                            <Typography variant="h3" color="white">
                                WorkWave
                            </Typography>
                            <IconButton
                                onClick={() => {
                                    collapseSidebar();
                                }}
                            >
                                <MenuOutlinedIcon />
                            </IconButton>
                        </Box>
                    ) : (
                        <MenuItem
                            icon={collapsed && <MenuOutlinedIcon />}
                            onClick={() => {
                                collapseSidebar();
                            }}
                            style={{ textAlign: 'center' }}
                        ></MenuItem>
                    )}
                    {!collapsed && (
                        <Box mb="25px">
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <img
                                    alt="profile"
                                    width="100px"
                                    height="100px"
                                    style={{
                                        borderRadius: '50px',
                                    }}
                                    src={ProfileImage}
                                />
                            </Box>
                            <Box textAlign="center">
                                <Typography
                                    variant="h3"
                                    color={'white'}
                                    fontWeight="bold"
                                    style={{ textTransform: 'capitalize' }}
                                >
                                    {currentUser.name +
                                        ' ' +
                                        currentUser.surname}
                                </Typography>
                                <Typography
                                    variant="h6"
                                    color={colors.secondary[800]}
                                    style={{ textTransform: 'capitalize' }}
                                >
                                    {currentUser.role}
                                </Typography>
                            </Box>
                        </Box>
                    )}

                    <MenuItem
                        icon={<HomeOutlinedIcon />}
                        component={<Link to="/" />}
                    >
                        Dashboard
                    </MenuItem>
                    <MenuItem
                        icon={<HomeOutlinedIcon />}
                        component={<Link to="/old" />}
                    >
                        Old Dashboard
                    </MenuItem>
                    <MenuItem
                        icon={<PeopleOutlinedIcon />}
                        component={<Link to="/employees" />}
                    >
                        Team
                    </MenuItem>
                    {/* <MenuItem icon={<BlurCircularOutlinedIcon />}>
                        Contacts
                    </MenuItem>
                    <MenuItem icon={<ReceiptOutlinedIcon />}>Profile</MenuItem>
                    <MenuItem icon={<HelpOutlineOutlinedIcon />}>FAQ</MenuItem>
                    <MenuItem icon={<CalendarTodayOutlinedIcon />}>
                        Calendar
                    </MenuItem> */}
                    <MenuItem
                        icon={<LogoutOutlinedIcon />}
                        onClick={confirmationDialogModalHandler}
                    >
                        Logout
                    </MenuItem>
                </Menu>
            </Sidebar>
            <ConfirmationDialog
                open={logout}
                handleOpen={confirmationDialogModalHandler}
                handleAgree={logoutHandler}
                title="Are you sure?"
                message="You will not be receiving any updates while you are logged out!"
            />
        </div>
    );
}

export default Drawer;
