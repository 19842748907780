import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
    name: 'job',
    initialState: {
        employees: [],
    },
    reducers: {
        getEmployees(state, action) {
            state.employees = action.payload;
        },
        addEmployee(state, action) {
            state.employees.push(action.payload);
        },
        deleteEmployee(state, action) {
            state.employees = state.employees.filter(
                (empl) => empl._id !== action.payload
            );
        },
        updateEmployee(state, action) {
            const index = state.employees.findIndex(
                (empl) => empl._id === action.payload._id
            );

            if (index !== -1) {
                state.employees[index] = {
                    ...state.employees[index],
                    ...action.payload,
                };
            }
        },
    },
});

export const employeesActions = userSlice.actions;

export default userSlice;
