//06/09/2023
const formatDateTime = (dateTime) => {
    const formattedDate = new Date(dateTime);

    const day = formattedDate.getDate();
    const month = formattedDate.toLocaleDateString('en-US', {
        month: 'short',
    });
    const year = formattedDate.getFullYear();

    const hours = formattedDate.getHours();
    const minutes = formattedDate.getMinutes();

    // Format the time as HH:MM
    const formattedTime = `${String(hours).padStart(2, '0')}:${String(
        minutes
    ).padStart(2, '0')}`;

    return {
        day,
        month,
        year,
        time: formattedTime, // Add formatted time
        fullDate: `${day} ${month} ${year}`,
        fullDateTime: `${day} ${month} ${year} ${formattedTime}`,
    };
};

export default formatDateTime;
