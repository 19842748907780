/*
 ** @date: 12/07/2023
 ** @version: 1.0.0
 ** @author: argertboja@gmail.com
 ** @description: This component creates a custom Checkbox MUI element with a label
 ********************************
 ** @Expected Props:
 ******** props.value: Object => Value of input field
 ******** props.label: String => Label of the input field
 ******** props.onChange: Function => Function which stores the input value
 */
import React from 'react';
import { FormGroup, FormControlLabel, Checkbox, useTheme } from '@mui/material';

const CustomCheckbox = (props) => {
    const theme = useTheme();
    return (
        <FormGroup>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={props.value}
                        color='primary'
                        style={{
                            color: theme.palette.primary.main,
                        }}
                        onChange={props.onChange}
                    />
                }
                label={<span style={{ color: '#3a4f4f' }}>{props.label}</span>}
            />
        </FormGroup>
    );
};

export default CustomCheckbox;
