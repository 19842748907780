// 17/09/2023
import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Box, Button } from '@mui/material';
import formatDate from '../../../utils/dateFormatter';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ClearIcon from '@mui/icons-material/Clear';
import { getJobLogs } from '../../../api/jobLogs-api';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

import formatDateTime from '../../../utils/dateFormatter';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';

const JobLogs = (props) => {
    const styles = {
        avatarContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
        },
        avatar: {
            width: 24,
            height: 24,
            mb: 0.5,
            mr: 0.5,
            ml: -1,
        },
        container: {
            display: 'flex', // Use 'display' instead of 'flexDirection'
            borderBottom: '1px solid gray', // Use 'borderBottom' instead of 'borderBottomWidth' and 'borderBottomColor'
        },
        avatarAndDashedLineContainer: {
            alignItems: 'center',
            margin: '10px 10px 0 10px', // Use 'margin' instead of 'marginHorizontal'
        },
        dashedLine: {
            borderStyle: 'dotted',
            borderWidth: '2px', // Use 'px' units for web
            borderRadius: '1px', // Use 'px' units for web
            height: '50px', // Use 'px' units for web
            width: 'fit-content',
            margin: 'auto',
        },
    };

    const [jobLogs, setJobLogs] = useState(null);

    useEffect(() => {
        async function getJobs() {
            try {
                const res = await getJobLogs(props.jobId);
                setJobLogs(res.data.jobLogs);
            } catch (error) {
                console.log('error', error);
            }
        }
        getJobs();
    }, [props.jobId]);

    const renderItem = ({ item }) => (
        <div style={styles.container}>
            <div style={styles.avatarAndDashedLineContainer}>
                <div
                    style={{
                        backgroundColor: item.user.avatarColor,
                        width: '30px',
                        height: '30px',
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '16px',
                        fontWeight: 'bold',
                    }}
                >
                    {item.user.name.charAt(0) + item.user.surname.charAt(0)}
                </div>
                <div
                    style={{
                        ...styles.dashedLine,
                        borderColor: item.user.avatarColor,
                    }}
                ></div>
            </div>
            <div style={{ justifyContent: 'center' }}>
                <div style={{ fontWeight: 'bold' }}>{item.description}</div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <CalendarMonthOutlinedIcon
                        sx={{
                            color: 'action.active',
                            mr: 0,
                            my: 0.5,
                        }}
                    />
                    <div style={{ padding: '5px' }}>
                        {formatDateTime(item.time).fullDate}
                    </div>
                    <AccessTimeIcon
                        sx={{
                            color: 'action.active',
                            mr: 0,
                            ml: 5,
                            my: 0.5,
                        }}
                    />
                    <div style={{ padding: '5px' }}>
                        {formatDate(item.time).time}
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <GroupOutlinedIcon
                        sx={{
                            color: 'action.active',
                            mr: 0,
                            my: 0.5,
                        }}
                    />
                    <div
                        style={{ padding: '5px' }}
                    >{`${item.user.name} ${item.user.surname}`}</div>
                </div>
            </div>
        </div>
    );

    return (
        <>
            <Modal
                open={props.open}
                onClose={props.toggle}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        maxWidth: 600,
                        bgcolor: '#e8e9eb',
                        maxHeight: 600,
                        borderRadius: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        fontSize={30}
                        fontWeight="bold"
                        textAlign={'center'}
                        color="#048282"
                    >
                        Job Logs
                    </Typography>
                    <div
                        style={{
                            width: '70%',
                            maxHeight: '480px',
                            overflow: 'auto',
                            marginBottom: '20px',
                        }}
                    >
                        <div
                            style={{
                                marginTop: '10px', // Adjust styles for web
                                width: '100%',
                            }}
                        >
                            {jobLogs &&
                                jobLogs.map((item) => (
                                    <div key={item._id}>
                                        {renderItem({ item })}
                                    </div>
                                ))}
                        </div>
                    </div>
                    <Button
                        variant="contained"
                        sx={{
                            mt: 1,
                            mb: 1,
                            width: '50%',
                            backgroundColor: 'gray',
                        }}
                        startIcon={<ClearIcon />}
                        onClick={props.toggle}
                    >
                        CLOSE
                    </Button>
                </Box>
            </Modal>
        </>
    );
};

export default JobLogs;
