/*
 ** @date: 23/07/2023
 ** @version: 1.0.0
 ** @author: argertboja@gmail.com
 ** @description: This component creates TextField INPUT element with an underline and icon on the left
 ********************************
 ** @Expected Props:
 ******** props.required: boolean => True if input field is mandatory to fill
 ******** props.label: String => Label of the input field
 ******** props.name: String => Identification name of the input element
 ******** props.autoFocus: boolean => True if the input field should be focused automatically
 ******** props.value: Object => Value of input field
 ******** props.hidePassword: boolean => True if input field should be of type password
 ******** props.icon: Icon => The Icon element to be displayed together with the Input field
 ******** props.onChange: Function => Function which stores the input value
 */
import React from 'react';
import { Box, TextField } from '@mui/material';

const TextFieldWithIconLabel = (props) => {
    const {
        icon: Icon,
        label,
        name,
        value,
        onChange,
        fullWidth,
        disabled,
        multiline,
    } = props;
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'flex-end',
            }}
        >
            <Icon
                sx={{
                    color: 'action.active',
                    mr: 1,
                    my: 0.5,
                }}
            />
            <TextField
                label={label}
                name={name}
                value={value}
                onChange={onChange}
                fullWidth={fullWidth}
                disabled={disabled}
                multiline={multiline}
                rows={5}
                variant={multiline ? 'outlined' : 'standard'}
            />
        </Box>
    );
};

export default TextFieldWithIconLabel;
