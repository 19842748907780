/*
 ** @date: 18/05/2023
 ** @version: 1.0.0
 ** @author: argertboja@gmail.com
 ** @description: Utility component to communicate with the API server regarding user requests
 ********************************
 */

import axios from 'axios';

const NAMESPACE = '/api/v1';

export async function fetchEmployees(token) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    const response = await axios.get(NAMESPACE + '/users/employees', config);
    return response;
}

export async function addEmployee(token, employee) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    const response = await axios.post(
        NAMESPACE + '/users/employees',
        employee,
        config
    );
    return response;
}

export async function deleteEmployee(token, employeeId) {
    const response = await axios.delete(NAMESPACE + '/users/employees', {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        data: {
            employeeId: employeeId,
        },
    });

    return response;
}

export async function updateEmployee(token, updatedEmployee) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    const response = await axios.patch(
        `${NAMESPACE}/users/employees`,
        updatedEmployee,
        config
    );
    return response;
}
