/*
 ** @date: 11/07/2023
 ** @version: 1.0.0
 ** @author: argertboja@gmail.com
 ** @description: This component creates TextField INPUT element with an underline and icon on the right
 ********************************
 ** @Expected Props:
 ******** props.required: boolean => True if input field is mandatory to fill
 ******** props.label: String => Label of the input field
 ******** props.name: String => Identification name of the input element
 ******** props.autoFocus: boolean => True if the input field should be focused automatically
 ******** props.value: Object => Value of input field
 ******** props.hidePassword: boolean => True if input field should be of type password
 ******** props.icon: Icon => The Icon element to be displayed together with the Input field
 ******** props.onChange: Function => Function which stores the input value
 */
import React from 'react';
import { TextField, useTheme } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';

const TextFieldWithIcon = (props) => {
    const theme = useTheme();
    return (
        <TextField
            variant='standard'
            required={props.required}
            fullWidth
            label={props.label}
            name={props.name}
            autoComplete={props.name}
            autoFocus={props.autoFocus}
            value={props.value}
            type={props.hidePassword ? 'password' : 'text'}
            sx={{
                borderBottom: '1px solid',
                borderBottomColor: theme.palette.primary.main,
            }}
            style={{ ...props.style }}
            onChange={(event) =>
                props.onChange(event.target.name, event.target.value)
            }
            InputProps={{
                endAdornment: (
                    <InputAdornment
                        position='end'
                        style={{
                            color: theme.palette.primary.main,
                        }}
                    >
                        {props.icon}
                    </InputAdornment>
                ),
            }}
            InputLabelProps={{
                style: {
                    color: theme.palette.primary.main,
                },
            }}
        />
    );
};

export default TextFieldWithIcon;
