/*
 ** @author: Argert Boja
 ** @date: 12/07/2023
 ** @version: 1.0.0
 ** @description: Form for resetting a password
 */

import React, { useState } from 'react';
import {
    Typography,
    Container,
    Grid,
    Button,
    IconButton,
    useTheme,
} from '@mui/material';
import QuestionActionText from '../../../components/custom/QuestionActionText';
import TextFieldWithIcon from '../../../components/custom/TextFieldWithIcon';
import ErrorAlert from '../../../components/custom/ErrorAlert';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { sendResetTokenToEmail, resetPassword } from '../../../api/auth';
import Countdown from '../../../components/custom/Countdown';

const ForgotPasswordForm = (props) => {
    const theme = useTheme();

    // state management
    const [error, setError] = useState(null);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [counterExpired, setCounterExpired] = useState(false);
    const [hidePassword, setHidePassword] = useState(true);
    const [hidePasswordConfirmation, setHidePasswordConfirmation] =
        useState(true);
    // state variables
    const [input, setInput] = useState({
        email: '',
        token: '',
        password: '',
        confirmPassword: '',
    });

    // handlers
    // handle input changes
    const inputChangeHandler = (name, value) => {
        setInput((oldInput) => ({
            ...oldInput,
            [name]: value,
        }));
    };

    // try to send reset password email
    async function sendResetEmailHandler() {
        try {
            const emailSent = await sendResetTokenToEmail(input.email);
            if (emailSent) {
                setShowNewPassword(true);
            }
        } catch (error) {
            setError(error.response.data.message);
        }
    }

    async function resetPasswordHandler() {
        try {
            const response = await resetPassword(
                input.token,
                input.password,
                input.confirmPassword
            );
            if (response) props.changeForm('login');
        } catch (error) {
            setError(error.response.data.message);
        }
    }

    // submit login handler
    const submitSendResetEmailHandler = (event) => {
        event.preventDefault();
        sendResetEmailHandler();
    };

    const submitResetPasswordHandler = (event) => {
        event.preventDefault();
        resetPasswordHandler();
    };

    const counterExpiredHandler = () => {
        setCounterExpired(true);
    };

    // show / hide password
    const hidePasswordHandler = () => {
        setHidePassword((prevHidePassword) => !prevHidePassword);
    };

    const hideConfirmPasswordHandler = () => {
        setHidePasswordConfirmation((prevHidePassword) => !prevHidePassword);
    };

    const resendEmailHandler = () => {
        setCounterExpired(false);
        setShowNewPassword(false);
    };

    return (
        <>
            <Typography
                fontSize={30}
                color={theme.palette.primary.main}
                fontWeight='bold'
            >
                Reset Password
            </Typography>
            <QuestionActionText
                question={
                    showNewPassword
                        ? 'Use the token to reset your password'
                        : 'Enter the email to send the reset token.'
                }
            />

            <Container
                maxWidth='xs'
                style={{
                    marginTop: '10px',
                }}
            >
                <form
                    onSubmit={
                        showNewPassword
                            ? submitResetPasswordHandler
                            : submitSendResetEmailHandler
                    }
                >
                    {showNewPassword ? (
                        <>
                            <Countdown
                                initialTime={120}
                                onFinish={counterExpiredHandler}
                            />
                            {counterExpired && (
                                <QuestionActionText
                                    question="Didn't receive an email?"
                                    actionText='Send again!'
                                    action={resendEmailHandler}
                                />
                            )}
                            <TextFieldWithIcon
                                label='Token'
                                name='token'
                                required
                                value={input.token}
                                onChange={inputChangeHandler}
                                style={{ marginTop: 5 }}
                            />
                            <Grid
                                container
                                style={{
                                    marginTop: '5px',
                                    marginBottom: '5px',
                                }}
                                spacing={2}
                            >
                                <Grid item xs={12} sm={6}>
                                    <TextFieldWithIcon
                                        label='Password'
                                        name='password'
                                        required
                                        value={input.password}
                                        onChange={inputChangeHandler}
                                        hidePassword={hidePassword}
                                        icon={
                                            <IconButton
                                                onClick={hidePasswordHandler}
                                                style={{
                                                    color: theme.palette.primary
                                                        .main,
                                                }}
                                                edge='end'
                                            >
                                                {hidePassword ? (
                                                    <VisibilityOutlinedIcon />
                                                ) : (
                                                    <VisibilityOffOutlinedIcon />
                                                )}
                                            </IconButton>
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextFieldWithIcon
                                        label='Confirm Password'
                                        name='confirmPassword'
                                        required
                                        value={input.confirmPassword}
                                        onChange={inputChangeHandler}
                                        hidePassword={hidePasswordConfirmation}
                                        icon={
                                            <IconButton
                                                onClick={
                                                    hideConfirmPasswordHandler
                                                }
                                                style={{
                                                    color: theme.palette.primary
                                                        .main,
                                                }}
                                                edge='end'
                                            >
                                                {hidePasswordConfirmation ? (
                                                    <VisibilityOutlinedIcon />
                                                ) : (
                                                    <VisibilityOffOutlinedIcon />
                                                )}
                                            </IconButton>
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </>
                    ) : (
                        <TextFieldWithIcon
                            label='Email Address'
                            name='email'
                            required
                            value={input.email}
                            onChange={inputChangeHandler}
                        />
                    )}
                    <ErrorAlert
                        style={{ marginTop: '10px' }}
                        error={error}
                        onClose={setError}
                        severity='error'
                    />
                    <Grid container style={{ marginBottom: '5px' }} spacing={2}>
                        {/* Carousel for app preview */}
                        <Grid item xs={12} sm={6}>
                            <Button
                                type='submit'
                                fullWidth
                                variant='contained'
                                color='primary'
                                style={{ marginTop: '15px' }}
                                startIcon={<EmailOutlinedIcon />}
                            >
                                {showNewPassword
                                    ? 'Set New Password'
                                    : 'Send Reset Token'}
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button
                                type='submit'
                                fullWidth
                                variant='contained'
                                color='error'
                                style={{ marginTop: '15px' }}
                                startIcon={<CancelOutlinedIcon />}
                                onClick={() => props.changeForm('login')}
                            >
                                CANCEL
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        </>
    );
};

export default ForgotPasswordForm;
