//26.08.2023
//01.08.2023
import React from "react";
import {
  Modal,
  Box,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Button,
  useTheme,
} from "@mui/material";

// Custom components
import TextFieldWithIconLabel from "../../../components/custom/TextFieldWithIconLabel";
import ErrorAlert from "../../../components/custom/ErrorAlert";
import CustomSelect from "../../../components/custom/CustomSelect";

// Icons
import ClearIcon from "@mui/icons-material/Clear";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import LowPriorityOutlinedIcon from "@mui/icons-material/LowPriorityOutlined";

const AddEmployeeForm = (props) => {
  const theme = useTheme();

  return (
    <Modal
      open={props.openForm}
      onClose={props.onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="modal-container">
        <Typography
          fontSize={30}
          color={theme.palette.primary.main}
          fontWeight="bold"
          textAlign={"center"}
        >
          {props.edittingEmployee ? "Edit Employee" : "Add Employee"}
        </Typography>
        <Box component="form" noValidate autoComplete="off">
          <Grid
            container
            style={{
              marginTop: "5px",
              marginBottom: "5px",
            }}
            spacing={2}
          >
            <Grid item xs={12} sm={6}>
              <TextFieldWithIconLabel
                icon={DescriptionOutlinedIcon}
                label="Username"
                name="username"
                value={props.employee.username}
                onChange={props.handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldWithIconLabel
                icon={LocationOnOutlinedIcon}
                label="Email"
                name="email"
                value={props.employee.email}
                onChange={props.handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldWithIconLabel
                icon={LocationOnOutlinedIcon}
                label="Name"
                name="name"
                value={props.employee.name}
                onChange={props.handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldWithIconLabel
                icon={LocationOnOutlinedIcon}
                label="Surname"
                name="surname"
                value={props.employee.surname}
                onChange={props.handleInputChange}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <LowPriorityOutlinedIcon sx={{ mt: 2, mr: 1 }} />
                <FormControl variant="standard" sx={{ minWidth: "90%" }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Role
                  </InputLabel>
                  <CustomSelect
                    value={props.employee.role}
                    onChange={props.handleInputChange}
                    label="Role"
                    name="role"
                    fullWidth
                    options={[
                      {
                        value: "admin",
                        label: "Administrator",
                      },
                      {
                        value: "worker",
                        label: "Worker",
                      },
                      {
                        value: "outsider",
                        label: "Outsider",
                      },
                    ]}
                  />
                </FormControl>
              </div>
            </Grid>
          </Grid>

          {/* Show error if something goes wrong */}
          <ErrorAlert
            style={{ marginTop: "10px" }}
            error={props.error}
            onClose={props.setError}
            severity="error"
          />
          {/* Form buttons */}
          <Grid container style={{ marginBottom: "5px" }} spacing={2}>
            <Grid item xs={12} sm={6}>
              <Button
                onClick={
                  props.edittingEmployee
                    ? props.editEmployeeHandler
                    : props.addEmployeeHandler
                }
                fullWidth
                variant="contained"
                color="primary"
                style={{ marginTop: "15px" }}
                startIcon={<PlaylistAddIcon />}
              >
                {props.edittingEmployee ? "Edit Employee" : "Add Employee"}
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                fullWidth
                variant="contained"
                color="error"
                style={{ marginTop: "15px" }}
                startIcon={<ClearIcon />}
                onClick={props.onClose}
              >
                CANCEL
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddEmployeeForm;
