import React from 'react';
import { List } from '@mui/material';
import JobListItem from './JobListItem';

const JobsList = ({ dailyJobs, detailedJobClickHandler }) => {
    return (
        <List
            sx={{
                width: '100%',
                bgcolor: '#e8e9eb',
                maxHeight: '44vh',
                position: 'relative',
                overflow: 'auto',
            }}
        >
            {dailyJobs &&
                dailyJobs.map((job, index) => (
                    <JobListItem
                        key={job._id}
                        index={index}
                        job={job}
                        onClick={detailedJobClickHandler}
                    />
                ))}
        </List>
    );
};

export default React.memo(JobsList);
