import { ColorModeContext, useMode } from './theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { Routes, Route } from 'react-router-dom';
import { ProSidebarProvider } from 'react-pro-sidebar';
import { useSelector } from 'react-redux';

import Sidebar from './components/global/Sidebar';
import DashboardScreen from './screens/DashboardScreen';
import DashboardScreenV2 from './screens/DashboardScreenV2';
import LoginRegisterScreen from './screens/LoginRegisterScreen';
import EmployeesScreen from './screens/EmployeesScreen';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const App = () => {
    const [theme, colorMode] = useMode();
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                {isAuthenticated ? (
                    <ProSidebarProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <CssBaseline />
                            <div className="app">
                                <Sidebar />
                                <main className="content">
                                    <Routes>
                                        <Route
                                            path="/"
                                            element={<DashboardScreenV2 />}
                                        />
                                        <Route
                                            path="/old"
                                            element={<DashboardScreen />}
                                        />
                                        <Route
                                            path="/employees"
                                            element={<EmployeesScreen />}
                                        />
                                    </Routes>
                                </main>
                            </div>
                        </LocalizationProvider>
                    </ProSidebarProvider>
                ) : (
                    <LoginRegisterScreen />
                )}
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
};

export default App;
