import axios from 'axios';
import dayjs from 'dayjs';

const NAMESPACE = '/api/v1';

export async function fetchJobs(token, params = {}) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: params, // Pass the provided params as query parameters
    };

    const response = await axios.get(NAMESPACE + '/jobs', config);

    let newItems = [];

    let loadedJobs = response.data.data.jobs;

    loadedJobs.forEach((job) => {
        let date = job.scheduledDate.split('T')[0];
        if (job.time) {
            date = date + 'T' + job.time;
        }
        newItems.push({
            id: job.id,
            start: date,
            end: dayjs(date)
                .add(job.duration ? job.duration : 2, 'hour')
                .format('YYYY-MM-DDTHH:mm'),
            ...job,
        });
    });

    return { jobs: newItems, groupedJobs: response.data.data.groupedJobs };
}

export async function createJob(token, job) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    const response = await axios.post(NAMESPACE + '/jobs/', job, config);
    return response;
}

export async function getCloseByJobs(jobData) {
    const response = await axios.post(
        NAMESPACE + '/jobs/close-by-jobs',
        jobData
    );
    return response;
}

export async function updateJobTime(token, id, newTime) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    const response = await axios.patch(
        `${NAMESPACE}/jobs/updateJobTime/${id}`,
        newTime,
        config
    );
    return response.data.data.job;
}

export async function getJob(id) {
    const response = await axios.get(`${NAMESPACE}/jobs/${id}`);
    return response.data.data.job;
}

export async function updateJob(token, id, updatedJob, assignedTo) {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
        },
    };
    const formData = new FormData();
    updatedJob.assignedTo = assignedTo;
    updatedJob.createdBy = updatedJob.createdBy._id
        ? updatedJob.createdBy._id
        : updatedJob.createdBy;
    Object.keys(updatedJob).forEach((key) => {
        if (
            key !== 'images' &&
            key !== 'tags' &&
            key !== 'deletedImages' &&
            key !== 'assignedTo'
        )
            formData.append(key, updatedJob[key]);
        else if (key === 'images') {
            updatedJob['images'].forEach((image, index) => {
                if (image instanceof File) {
                    formData.append('images', image);
                } else {
                    formData.append('existingImages', image);
                }
            });
        } else if (
            key === 'tags' ||
            key === 'deletedImages' ||
            key === 'assignedTo'
        )
            formData.append(key, JSON.stringify(updatedJob[key]));
    });

    const response = await axios.patch(
        `${NAMESPACE}/jobs/${id}`,
        formData,
        config
    );

    let newItems = [];

    let loadedJobs = response.data.data.allJobs;

    loadedJobs.forEach((job) => {
        let date = job.scheduledDate.split('T')[0];
        if (job.time) {
            date = date + 'T' + job.time;
        }
        newItems.push({
            id: job.id,
            start: date,
            end: dayjs(date)
                .add(job.duration ? job.duration : 2, 'hour')
                .format('YYYY-MM-DDTHH:mm'),
            ...job,
        });
    });

    return {
        job: response.data.data.job,
        jobs: newItems,
        groupedJobs: response.data.data.groupedJobs,
        activeDates: response.data.data.activeDates,
    };
}

export async function deleteJob(token, jobId) {
    const response = await axios.delete(`${NAMESPACE}/jobs/${jobId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    let newItems = [];

    let loadedJobs = response.data.data.allJobs;

    loadedJobs.forEach((job) => {
        let date = job.scheduledDate.split('T')[0];
        if (job.time) {
            date = date + 'T' + job.time;
        }
        newItems.push({
            id: job.id,
            start: date,
            end: dayjs(date)
                .add(job.duration ? job.duration : 2, 'hour')
                .format('YYYY-MM-DDTHH:mm'),
            ...job,
        });
    });

    return {
        jobs: newItems,
        groupedJobs: response.data.data.groupedJobs,
        activeDates: response.data.data.activeDates,
    };
}

export async function fetchActiveDates(token, params = {}) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: params, // Pass the provided params as query parameters
    };

    const response = await axios.get(NAMESPACE + '/jobs/active-dates', config);

    return {
        activeDates: response.data.data.activeDates,
        jobs: response.data.data.jobs,
    };
}

export async function fetchJobsByDate(token, date, params = {}) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: params,
    };

    const response = await axios.get(
        `${NAMESPACE}/jobs/jobs-by-date/${date}`,
        config
    );

    return { jobs: response.data.data.jobs };
}
