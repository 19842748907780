// date: 08/02/2023

import { configureStore } from '@reduxjs/toolkit';

//import reducers
import authSlice from './auth-slice';
import jobSlice from './job-slice';
import userSlice from './user-slice';

const store = configureStore({
    reducer: {
        auth: authSlice.reducer,
        job: jobSlice.reducer,
        user: userSlice.reducer,
    },
});

export default store;
