/*
 ** @author: Argert Boja
 ** @date: 12/07/2023
 ** @version: 1.0.0
 ** @description: Register form used to signup a new user
 */
import React, { useState } from 'react';
import { createUser } from '../../../api/auth';

// MUI
import {
    IconButton,
    useTheme,
    Button,
    Container,
    Typography,
    Grid,
} from '@mui/material';

// Icons
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

// Custom components
import QuestionActionText from '../../../components/custom/QuestionActionText';
import TextFieldWithIcon from '../../../components/custom/TextFieldWithIcon';
import SocialLogin from '../../../components/custom/SocialLogin';
import ErrorAlert from '../../../components/custom/ErrorAlert';

const RegisterForm = (props) => {
    const theme = useTheme();

    // state management
    const [error, setError] = useState(null);
    // state variables
    const [user, setUser] = useState({
        name: '',
        surname: '',
        username: '',
        password: '',
        confirmPassword: '',
        email: '',
    });
    const [hidePassword, setHidePassword] = useState(true);
    const [hidePasswordConfirmation, setHidePasswordConfirmation] =
        useState(true);

    // handlers
    // handle input changes
    const handleInputChange = (name, value) => {
        setUser((oldUser) => ({
            ...oldUser,
            [name]: value,
        }));
    };

    // show / hide password
    const hidePasswordHandler = () => {
        setHidePassword((prevHidePassword) => !prevHidePassword);
    };

    const hideConfirmPasswordHandler = () => {
        setHidePasswordConfirmation((prevHidePassword) => !prevHidePassword);
    };

    // sign up user
    async function signupHandler(user) {
        try {
            const res = await createUser(user);
            // dispatch(
            //     authActions.authenticate({
            //         token: res.token,
            //         user: res.data.user,
            //         rememberMe: false,
            //         username: res.data.user.email,
            //         password: res.data.user.password,
            //     })
            // );
            if (res) {
                props.changeForm('login');
            }
            setError(null);
            return res;
        } catch (error) {
            // show error message if user cannot signup
            setError(error.response.data.message);
        }
    }

    // submit login handler
    const handleSignupSubmit = (event) => {
        event.preventDefault();
        signupHandler(user);
    };

    return (
        <>
            <Typography
                fontSize={30}
                color={theme.palette.primary.main}
                fontWeight='bold'
            >
                Create Account
            </Typography>
            <QuestionActionText
                question='Already have an account?'
                actionText='Signin now!'
                action={() => props.changeForm('login')}
            />
            <Container
                maxWidth='xs'
                style={{
                    height: '70%',
                    borderTopLeftRadius: '20px',
                    borderTopRightRadius: '20px',
                }}
            >
                <form onSubmit={handleSignupSubmit}>
                    <Grid
                        container
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        spacing={2}
                    >
                        {/* Carousel for app preview */}
                        <Grid item xs={12} sm={6}>
                            <TextFieldWithIcon
                                label='Name'
                                name='name'
                                required
                                value={user.name}
                                autoFocus
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextFieldWithIcon
                                label='Surname'
                                name='surname'
                                required
                                value={user.surname}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextFieldWithIcon
                                label='Username'
                                name='username'
                                required
                                value={user.username}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextFieldWithIcon
                                label='Email Address'
                                name='email'
                                required
                                value={user.email}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextFieldWithIcon
                                label='Password'
                                name='password'
                                required
                                value={user.password}
                                onChange={handleInputChange}
                                hidePassword={hidePassword}
                                icon={
                                    <IconButton
                                        onClick={hidePasswordHandler}
                                        style={{
                                            color: theme.palette.primary.main,
                                        }}
                                        edge='end'
                                    >
                                        {hidePassword ? (
                                            <VisibilityOutlinedIcon />
                                        ) : (
                                            <VisibilityOffOutlinedIcon />
                                        )}
                                    </IconButton>
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextFieldWithIcon
                                label='Confirm Password'
                                name='confirmPassword'
                                required
                                value={user.confirmPassword}
                                onChange={handleInputChange}
                                hidePassword={hidePasswordConfirmation}
                                icon={
                                    <IconButton
                                        onClick={hideConfirmPasswordHandler}
                                        style={{
                                            color: theme.palette.primary.main,
                                        }}
                                        edge='end'
                                    >
                                        {hidePasswordConfirmation ? (
                                            <VisibilityOutlinedIcon />
                                        ) : (
                                            <VisibilityOffOutlinedIcon />
                                        )}
                                    </IconButton>
                                }
                            />
                        </Grid>
                    </Grid>
                    <ErrorAlert
                        error={error}
                        onClose={setError}
                        severity='error'
                    />
                    <Button
                        type='submit'
                        fullWidth
                        variant='contained'
                        color='primary'
                        style={{ marginTop: '15px' }}
                        startIcon={<LockOutlinedIcon />}
                    >
                        SIGNUP
                    </Button>
                </form>
                <SocialLogin />
            </Container>
        </>
    );
};

export default RegisterForm;
