//01.08.2023
//https://work-wave-server.onrender.com/
import React, { useState } from 'react';
import {
    Modal,
    Box,
    Typography,
    Grid,
    TextField,
    Chip,
    FormControl,
    InputLabel,
    Button,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    ListItemButton,
    Checkbox,
    Avatar,
    useTheme,
    CircularProgress,
    Backdrop,
} from '@mui/material';

// Custom components
import TextFieldWithIconLabel from '../../../components/custom/TextFieldWithIconLabel';
import ErrorAlert from '../../../components/custom/ErrorAlert';
import CustomDateTimePicker from '../../../components/custom/CustomDateTimePicker';
import CustomSelect from '../../../components/custom/CustomSelect';

// Icons
import ClearIcon from '@mui/icons-material/Clear';
import TitleIcon from '@mui/icons-material/Title';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import TagOutlinedIcon from '@mui/icons-material/TagOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import LowPriorityOutlinedIcon from '@mui/icons-material/LowPriorityOutlined';
import PhoneEnabledOutlinedIcon from '@mui/icons-material/PhoneEnabledOutlined';
import ViewTimelineOutlinedIcon from '@mui/icons-material/ViewTimelineOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

import JobLogs from './JobLogs';

import GradingIcon from '@mui/icons-material/Grading';

import { useSelector } from 'react-redux';

const AddJobForm = (props) => {
    const theme = useTheme();

    // State management
    const [timePickerOpen, settimePickerOpen] = useState(false);
    const [employeeOpen, setEmployeeOpen] = useState(false);

    // Handlers
    const openTimePickerModalHandler = () => settimePickerOpen(true);
    const handleTimePickerClose = () => settimePickerOpen(false);
    const handleEmployeeOpen = () => setEmployeeOpen(true);
    const handleEmployeeClose = () => setEmployeeOpen(false);

    const [openJobLogs, setOpenJobLogs] = useState(false);

    const handleToggleJobLogsModal = () => {
        setOpenJobLogs(!openJobLogs);
    };

    const user = useSelector((state) => state.auth.currentUser);

    return (
        <>
            {props.job._id && (
                <JobLogs
                    open={openJobLogs}
                    toggle={handleToggleJobLogsModal}
                    jobId={props.job._id}
                />
            )}
            <Modal
                open={props.openForm}
                onClose={props.onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '50%',
                        backgroundColor: 'white',
                        padding: '20px',
                        height: '90%',
                    }}
                >
                    <Backdrop
                        sx={{
                            color: '#fff',
                            zIndex: 900,
                        }}
                        open={props.loading}
                    >
                        <CircularProgress style={{ color: '#048282' }} />
                    </Backdrop>
                    <Typography
                        fontSize={30}
                        color={theme.palette.primary.main}
                        fontWeight="bold"
                        textAlign={'center'}
                    >
                        {props.edittingEvent ? 'Edit Job' : 'Add Job'}
                    </Typography>
                    <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '95%',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                height: '95%',
                            }}
                        >
                            <Grid
                                container
                                style={{
                                    marginTop: '5px',
                                    marginBottom: '5px',
                                }}
                                spacing={2}
                            >
                                <Grid item xs={12} sm={12}>
                                    <Grid
                                        container
                                        style={{
                                            marginTop: '5px',
                                            marginBottom: '5px',
                                        }}
                                        spacing={2}
                                    >
                                        <Grid item xs={12} sm={6}>
                                            <TextFieldWithIconLabel
                                                icon={TitleIcon}
                                                label="Job Title"
                                                name="jobTitle"
                                                value={props.job.jobTitle}
                                                onChange={
                                                    props.handleInputChange
                                                }
                                                fullWidth
                                                disabled={
                                                    user.role !== 'admin' &&
                                                    props.edittingEvent
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextFieldWithIconLabel
                                                icon={LocationOnOutlinedIcon}
                                                label="Address"
                                                name="address"
                                                value={props.job.address}
                                                onChange={
                                                    props.handleInputChange
                                                }
                                                fullWidth
                                                disabled={
                                                    user.role !== 'admin' &&
                                                    props.edittingEvent
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextFieldWithIconLabel
                                                icon={LocationOnOutlinedIcon}
                                                label="Postal Code"
                                                name="postalCode"
                                                value={
                                                    props.job.postalCode &&
                                                    props.job.postalCode.toUpperCase()
                                                }
                                                onChange={
                                                    props.handleInputChange
                                                }
                                                fullWidth
                                                disabled={
                                                    user.role !== 'admin' &&
                                                    props.edittingEvent
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'flex-end',
                                                    width: '100%',
                                                }}
                                            >
                                                <CalendarMonthOutlinedIcon
                                                    sx={{
                                                        color: 'action.active',
                                                        mr: 1,
                                                        my: 0.5,
                                                    }}
                                                />
                                                <TextField
                                                    label="Due date"
                                                    variant="standard"
                                                    onClick={
                                                        openTimePickerModalHandler
                                                    }
                                                    value={
                                                        props.job.scheduledDate
                                                            ? props.job
                                                                  .scheduledDate +
                                                              ' ' +
                                                              props.job.time
                                                            : ''
                                                    }
                                                    fullWidth
                                                    disabled={
                                                        user.role !== 'admin' &&
                                                        props.edittingEvent
                                                    }
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <LowPriorityOutlinedIcon
                                                    sx={{ mt: 2, mr: 1 }}
                                                />
                                                <FormControl
                                                    variant="standard"
                                                    sx={{ minWidth: '90%' }}
                                                    disabled={
                                                        user.role !== 'admin' &&
                                                        props.edittingEvent
                                                    }
                                                >
                                                    <InputLabel id="demo-simple-select-standard-label">
                                                        Duration
                                                    </InputLabel>
                                                    <CustomSelect
                                                        value={
                                                            props.job.duration
                                                        }
                                                        onChange={
                                                            props.handleInputChange
                                                        }
                                                        label="Duration (h)"
                                                        name="duration"
                                                        fullWidth
                                                        options={[
                                                            2, 3, 4, 5, 6, 7, 8,
                                                        ]}
                                                    />
                                                </FormControl>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'flex-end',
                                                }}
                                            >
                                                <GroupOutlinedIcon
                                                    sx={{
                                                        color: 'action.active',
                                                        mr: 1,
                                                        my: 0.5,
                                                    }}
                                                />
                                                <TextField
                                                    label="Assign to"
                                                    variant="standard"
                                                    onClick={handleEmployeeOpen}
                                                    fullWidth
                                                    disabled={
                                                        user.role !== 'admin' &&
                                                        props.edittingEvent
                                                    }
                                                    InputProps={
                                                        props.employees.some(
                                                            (employee) =>
                                                                employee.selected
                                                        )
                                                            ? {
                                                                  startAdornment:
                                                                      (
                                                                          <Box
                                                                              sx={{
                                                                                  display:
                                                                                      'flex',
                                                                                  alignItems:
                                                                                      'center',
                                                                              }}
                                                                          >
                                                                              {props.employees
                                                                                  .filter(
                                                                                      (
                                                                                          employee
                                                                                      ) =>
                                                                                          employee.selected
                                                                                  )
                                                                                  .map(
                                                                                      (
                                                                                          chip
                                                                                      ) => (
                                                                                          <Chip
                                                                                              key={
                                                                                                  chip._id
                                                                                              }
                                                                                              label={
                                                                                                  chip.name
                                                                                              }
                                                                                              onDelete={() =>
                                                                                                  props.handleToggle(
                                                                                                      chip._id
                                                                                                  )
                                                                                              }
                                                                                              style={{
                                                                                                  marginRight: 8,
                                                                                              }}
                                                                                          />
                                                                                      )
                                                                                  )}
                                                                          </Box>
                                                                      ),
                                                              }
                                                            : {}
                                                    }
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <LowPriorityOutlinedIcon
                                                    sx={{ mt: 2, mr: 1 }}
                                                />
                                                <FormControl
                                                    variant="standard"
                                                    sx={{ minWidth: '90%' }}
                                                    disabled={
                                                        user.role !== 'admin' &&
                                                        props.edittingEvent
                                                    }
                                                >
                                                    <InputLabel id="demo-simple-select-standard-label">
                                                        Priority
                                                    </InputLabel>
                                                    <CustomSelect
                                                        value={
                                                            props.job.priority
                                                        }
                                                        onChange={
                                                            props.handleInputChange
                                                        }
                                                        label="Priority"
                                                        name="priority"
                                                        fullWidth
                                                        options={[
                                                            {
                                                                value: 0,
                                                                label: 'Low',
                                                            },
                                                            {
                                                                value: 1,
                                                                label: 'Medium',
                                                            },
                                                            {
                                                                value: 2,
                                                                label: 'High',
                                                            },
                                                        ]}
                                                    />
                                                </FormControl>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextFieldWithIconLabel
                                                icon={PhoneEnabledOutlinedIcon}
                                                label="Phone Number"
                                                name="phone"
                                                value={props.job.phone}
                                                onChange={
                                                    props.handleInputChange
                                                }
                                                fullWidth
                                                disabled={
                                                    user.role !== 'admin' &&
                                                    props.edittingEvent
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextFieldWithIconLabel
                                                icon={TagOutlinedIcon}
                                                label="Tags (Space Seprated Values)"
                                                name="tags"
                                                value={props.job.tags.join(' ')}
                                                onChange={
                                                    props.handleInputChange
                                                }
                                                fullWidth
                                                disabled={
                                                    user.role !== 'admin' &&
                                                    props.edittingEvent
                                                }
                                            />
                                        </Grid>
                                        {props.edittingEvent && (
                                            <Grid item xs={12} sm={6}>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <ViewTimelineOutlinedIcon
                                                        sx={{ mt: 2, mr: 1 }}
                                                    />
                                                    <FormControl
                                                        variant="standard"
                                                        sx={{ minWidth: 120 }}
                                                    >
                                                        <InputLabel id="demo-simple-select-standard-label">
                                                            Job Status
                                                        </InputLabel>
                                                        <CustomSelect
                                                            value={
                                                                props.job.status
                                                            }
                                                            onChange={
                                                                props.handleInputChange
                                                            }
                                                            label="Job Status"
                                                            name="status"
                                                        />
                                                    </FormControl>
                                                </div>
                                            </Grid>
                                        )}
                                        <Grid item xs={12} sm={12}>
                                            <TextFieldWithIconLabel
                                                icon={DescriptionOutlinedIcon}
                                                label="Job Description"
                                                name="jobDescription"
                                                value={props.job.jobDescription}
                                                multiline
                                                onChange={
                                                    props.handleInputChange
                                                }
                                                fullWidth
                                                disabled={
                                                    user.role !== 'admin' &&
                                                    props.edittingEvent
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                        {/* Show error if something goes wrong */}
                        <ErrorAlert
                            style={{ marginTop: '10px' }}
                            error={props.error}
                            onClose={props.setError}
                            severity="error"
                        />
                        {/* Form buttons */}
                        <Grid
                            container
                            style={{ marginBottom: '5px' }}
                            spacing={2}
                        >
                            <Grid item xs={12} sm={props.edittingEvent ? 4 : 6}>
                                <Button
                                    onClick={
                                        props.edittingEvent
                                            ? props.editJobHandler
                                            : props.handleSubmit
                                    }
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    style={{ marginTop: '15px' }}
                                    startIcon={<PlaylistAddIcon />}
                                >
                                    {props.edittingEvent
                                        ? 'Edit Job'
                                        : 'Add Job'}
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={props.edittingEvent ? 4 : 6}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="error"
                                    style={{ marginTop: '15px' }}
                                    startIcon={<ClearIcon />}
                                    onClick={props.onClose}
                                >
                                    CANCEL
                                </Button>
                            </Grid>
                            {props.edittingEvent && (
                                <Grid item xs={12} sm={4}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        style={{
                                            marginTop: '15px',
                                            backgroundColor: 'gray',
                                        }}
                                        startIcon={<GradingIcon />}
                                        onClick={handleToggleJobLogsModal}
                                    >
                                        LOG
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                        {/* Modal for the date time picker */}
                        <Modal
                            open={timePickerOpen}
                            onClose={handleTimePickerClose}
                            aria-labelledby="child-modal-title"
                            aria-describedby="child-modal-description"
                        >
                            <Box
                                className="modal-container"
                                sx={{ width: 'fit-content' }}
                            >
                                <CustomDateTimePicker
                                    value={
                                        props.job.scheduledDate +
                                        ' ' +
                                        props.job.time
                                    }
                                    name="scheduledDate"
                                    onChange={props.handleInputChange}
                                    handleTimePickerClose={
                                        handleTimePickerClose
                                    }
                                />
                            </Box>
                        </Modal>
                        {/* Modal for assigning a job to an employee */}
                        <Modal
                            open={employeeOpen}
                            onClose={handleEmployeeClose}
                            aria-labelledby="child-modal-title"
                            aria-describedby="child-modal-description"
                        >
                            <Box
                                className="modal-container"
                                sx={{ width: 'fit-content' }}
                            >
                                <List
                                    dense
                                    sx={{
                                        width: '100%',
                                        maxWidth: 360,
                                        bgcolor: 'background.paper',
                                    }}
                                >
                                    {props.employees.map((value) => {
                                        const labelId = value._id;
                                        return (
                                            <ListItem
                                                key={value._id}
                                                secondaryAction={
                                                    <Checkbox
                                                        edge="end"
                                                        onChange={() =>
                                                            props.handleToggle(
                                                                value._id
                                                            )
                                                        }
                                                        checked={
                                                            value.selected
                                                                ? value.selected
                                                                : false
                                                        }
                                                        inputProps={{
                                                            'aria-labelledby':
                                                                labelId,
                                                        }}
                                                    />
                                                }
                                                disablePadding
                                            >
                                                <ListItemButton
                                                    onClick={() =>
                                                        props.handleToggle(
                                                            value._id
                                                        )
                                                    }
                                                >
                                                    <ListItemAvatar>
                                                        <Avatar
                                                            alt={`Avatar n°${value.name}`}
                                                            src={`/static/images/avatar/${
                                                                value + 1
                                                            }.jpg`}
                                                        />
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        id={labelId}
                                                        primary={
                                                            value.name +
                                                            ' ' +
                                                            value.surname
                                                        }
                                                    />
                                                </ListItemButton>
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            </Box>
                        </Modal>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default AddJobForm;
