import axios from 'axios';

const NAMESPACE = '/api/v1';

export async function getJobComments(token, jobId) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    const response = await axios.get(
        `${NAMESPACE}/jobComments/${jobId}`,
        config
    );
    return response;
}

export async function addImageOrComment(token, comment) {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
        },
    };
    const formData = new FormData();
    if (Array.isArray(comment.images) && comment.images.length > 0) {
        comment.images.forEach((image) => {
            if (image instanceof File) {
                formData.append('images', image);
            }
        });
    } else {
        formData.append('note', comment.note);
    }
    formData.append('jobId', comment.jobId);
    formData.append('createdBy', comment.createdBy);

    const response = await axios.post(
        NAMESPACE + '/jobComments/imageOrComment',
        formData,
        config
    );
    return response;
}

export async function addComment(token, comment) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    const response = await axios.post(
        NAMESPACE + '/jobComments/',
        comment,
        config
    );
    return response;
}

export async function deleteJobComment(token, id) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    const response = await axios.delete(
        `${NAMESPACE}/jobComments/${id}`,
        config
    );
    return response;
}

export async function updateJobComment(token, id, note) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    const response = await axios.patch(
        `${NAMESPACE}/jobComments/${id}`,
        { note: note },
        config
    );
    return response;
}
