import { Box } from '@mui/material';
import React from 'react';

const CalendarJobViewMonth = () => {
    return (
        <Box width={8} height={8} borderRadius={10} bgcolor={'#048282'}></Box>
    );
};

export default CalendarJobViewMonth;
