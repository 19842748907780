/*
 ** @date: 10/09/2023
 ** @version: 1.0.0
 ** @author: argertboja@gmail.com
 ** @description: Calendar Job View Week
 ********************************
 ** @Expected Props:
 ******** props.info: Object => Job object to fill data of view
 ** @Current version:
 ********
 ** @TODOs:
 ******** TODO:
 */
import React from 'react';

import { Box } from '@mui/material';

// Icons
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

const CalendarJobViewWeek = (props) => {
    const job = props.info.event.extendedProps;
    const color =
        job.priority === 0
            ? '#048282'
            : job.priority === 1
            ? '#eba434'
            : '#941515'; //"#43515c";

    const styles = {
        jobContainer: {
            borderLeftColor: color,
            borderTopColor:
                job.assignedTo &&
                job.assignedTo[0] &&
                job.assignedTo[0].avatarColor,
            borderTopWidth: '5px',
            borderTopStyle: 'solid',
            borderRadius: '5px',
            height: '100%',
            borderLeftWidth: '5px',
            borderLeftStyle: 'solid',
        },
        jobTitle: {
            color: color,
            fontSize: 14,
            paddingLeft: 3,
            overflow: 'hidden',
        },
        infoContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
        },
        address: {
            color: 'black',
            display: 'flex',
            alignItems: 'center',
            overflow: 'hidden',
        },
        avatarContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
        },
        avatar: {
            width: 24,
            height: 24,
            mb: 0.5,
            mr: 0.5,
            ml: -1,
        },
    };
    return (
        <Box style={styles.jobContainer}>
            <Box style={styles.jobTitle}>{job && job.jobTitle}</Box>
            <Box style={styles.infoContainer}>
                <Box style={styles.address}>
                    <LocationOnOutlinedIcon sx={{ mr: 0.5 }} />
                    {job && job.postalCode}
                </Box>
            </Box>
        </Box>
    );
};

export default CalendarJobViewWeek;
