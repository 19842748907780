/*
 ** @date: 19/06/2023
 ** @version: 1.0.0
 ** @author: argertboja@gmail.com
 ** @description: This component creates a custom Confirmation Dialog which asks a question
 **               and based on the answer performs some action to confirm or cancel an action
 ********************************
 ** @Expected Props:
 ******** props.open: boolean => State of the dialog whether it should be opened or not
 ******** props.handleOpen: Function => Handle the state of the dialog open/close
 ******** props.title: String => Title of the dialog box
 ******** props.message: String => Message of the dialog box
 ******** props.handleAgree: Function => Action that needs to be performed if the user affirms the question
 */
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

const ConfirmationDialog = (props) => {
    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.handleOpen}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title' sx={{ fontSize: 20 }}>
                    {props.title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        {props.message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleOpen}>Cancel</Button>
                    <Button onClick={props.handleAgree} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ConfirmationDialog;
