import { createSlice } from '@reduxjs/toolkit';

const jobSlice = createSlice({
    name: 'job',
    initialState: {
        jobs: [],
        groupedJobs: [],
    },
    reducers: {
        fetchJobs(state, action) {
            state.jobs = action.payload.jobs;
            state.groupedJobs = action.payload.groupedJobs;
        },
        addJob(state, action) {
            state.jobs = [...state.jobs, action.payload];
            const date = action.payload.start.split('T')[0];
            if (!state.groupedJobs[date]) {
                state.groupedJobs[date] = [];
            }
            state.groupedJobs[date].push(action.payload);
        },
    },
});

export const jobActions = jobSlice.actions;

export default jobSlice;
