/*
 ** @date: 08/05/2023
 ** @version: 1.0.0
 ** @author: argertboja@gmail.com
 ** @description: This component creates a row of Social Network icons which will be used to login and register a user
 ********************************
 ** @Expected Props:
 ** @Current version:
 ******** Displays Social Network Icons
 ** @TODOs:
 ******** TODO: Login using social networks
 ******** TODO: Register using social networks
 */
import React from 'react';
import { Grid } from '@mui/material';
import FacebookIcon from './../../assets/facebook.png';
import GoogleIcon from './../../assets/google.png';
import LinkedInIcon from './../../assets/linkedin.png';

const ImagesGrid = () => {
    return (
        <Grid container spacing={2} style={{ marginTop: '5px' }}>
            <Grid
                item
                xs={4}
                display='flex'
                alignItems='center'
                justifyContent='center'
            >
                <img
                    src={FacebookIcon}
                    style={{
                        width: '30px',
                        borderRadius: '15px',
                        overflow: 'hidden',
                    }}
                    alt='fb-icon'
                />
            </Grid>
            <Grid
                item
                xs={4}
                display='flex'
                alignItems='center'
                justifyContent='center'
            >
                <img
                    src={GoogleIcon}
                    style={{
                        width: '30px',
                        borderRadius: '15px',
                        overflow: 'hidden',
                    }}
                    alt='google-icon'
                />
            </Grid>
            <Grid
                item
                xs={4}
                display='flex'
                alignItems='center'
                justifyContent='center'
            >
                <img
                    src={LinkedInIcon}
                    style={{
                        width: '30px',
                        borderRadius: '15px',
                        overflow: 'hidden',
                    }}
                    alt='li-icon'
                />
            </Grid>
        </Grid>
    );
};

export default ImagesGrid;
