import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
    createJob,
    getJob,
    updateJob,
    getCloseByJobs,
    deleteJob,
    fetchActiveDates,
    fetchJobsByDate,
} from '../api/job-api';
import { fetchEmployees } from '../api/user-api';
import {
    Box,
    IconButton,
    InputBase,
    useTheme,
    Avatar,
    Input,
    CircularProgress,
    Backdrop,
} from '@mui/material';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlgin from '@fullcalendar/interaction';
import dayjs from 'dayjs';
import ConfirmationDialog from '../components/custom/CustomConfirmationDialog';
import { Button, Typography } from '@mui/material';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import LowPriorityOutlinedIcon from '@mui/icons-material/LowPriorityOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
// Custom components

// Styles
import './../assets/css/dashboard-style.css';
import AddJobForm from './screen_parts/Dashboard/AddJobForm';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

import SearchIcon from '@mui/icons-material/Search';
import { tokens } from '../theme';
import CalendarJobViewMonth from './screen_parts/Dashboard/CalendarJobViewMonth';
import ErrorAlert from '../components/custom/ErrorAlert';
import formatDateTime from '../utils/dateFormatter';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import TitleIcon from '@mui/icons-material/Title';
import { Folder, ViewTimelineOutlined } from '@mui/icons-material';
import AttachmentIcon from '@mui/icons-material/Attachment';
import { formatStatus } from '../utils/utilityFunctions';
import JobComments from './screen_parts/Dashboard/JobComments';
import JobImageOrComment from './screen_parts/Dashboard/JobImageOrComment';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { getJobComments } from '../api/jobComments-api';

import SearchList from './screen_parts/Dashboard/SearchList';
import JobsList from './screen_parts/Dashboard/JobsList';

import { addImageOrComment } from '../api/jobComments-api';

const Dashboard = () => {
    const [loading, setLoading] = useState(false);
    const initialState = {
        jobTitle: '',
        createdBy: '',
        jobDescription: '',
        address: '',
        postalCode: '',
        phone: '',
        tags: [],
        scheduledDate: '',
        priority: 1,
        images: [],
        deletedImages: [],
        assignedTo: [],
        status: '',
        time: '',
        duration: 2,
    };
    const calendarRef = useRef(null);

    // state management
    const [selected, setSelected] = useState(null);
    const [edittingEvent, setEdittingEvent] = useState(false);
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(null);
    const [job, setJob] = useState(initialState);
    const [fetchedEmployees, setFetchedEmployees] = useState([]);
    const [employees, setEmployees] = useState(fetchedEmployees);
    const [dailyJobs, setDailyJobs] = useState([]);
    const [detailedJob, setDetailedJob] = useState(null);
    const [activeDates, setActiveDates] = useState([]);
    const [personName, setPersonName] = React.useState([]);
    const [selectedStatus, setSelectedStatus] = React.useState([]);

    // redux state
    const token = useSelector((state) => state.auth.token);
    const currentUser = useSelector((state) => state.auth.currentUser);
    const [currentDate, setCurrentDate] = useState(
        new Date().toISOString().split('T')[0]
    );

    // Handlers
    const openModalHandler = () => setOpen(true);

    const handleInputChange = (event) => {
        let { name, value } = event.target;
        if (name === 'tags') value = value.split(' ');
        if (name === 'postalCode') value = value.toUpperCase();
        if (name === 'scheduledDate') {
            const date = dayjs(value).format('YYYY-MM-DD');
            const time = dayjs(value).format('HH:mm');
            setJob((oldJob) => ({
                ...oldJob,
                scheduledDate: date,
                time: time,
            }));
        } else {
            setJob((oldJob) => ({
                ...oldJob,
                [name]: value,
            }));
        }
    };

    function eventContent() {
        return <CalendarJobViewMonth />;
    }

    const handleToggle = (value) => {
        setEmployees((prevItems) => {
            return prevItems.map((item) => {
                if (item._id === value) {
                    return {
                        ...item,
                        selected: !item.selected,
                    };
                }
                return item;
            });
        });
    };

    const handleDateClick = async (selected) => {
        setShowSearchResults(false);
        const date = new Date(selected.startStr).toISOString().split('T')[0];
        setLoading(true);
        const dailyJobsResponse = await fetchJobsByDate(token, date, {
            ids: personName,
            statuses: selectedStatus,
            searchText: searchText,
        });
        setDailyJobs(dailyJobsResponse.jobs);

        setLoading(false);
        setCurrentDate(date);
        setDetailedJob(null);
    };

    const newJobHandler = () => {
        setJob(initialState);
        const date = new Date();
        //const startDate = date.toISOString().split('T')[0];
        openModalHandler();
        setEmployees(fetchedEmployees);
        setSelected(selected);
        const time = dayjs(date).format('HH:mm');
        setJob((oldJob) => ({
            ...oldJob,
            time: time,
        }));
    };

    const [editDetailedJob, setEditDetailedJob] = useState(false);

    const editDetailedJobHandler = (job) => {
        setEditDetailedJob(true);
        handleJobClick(job);
    };

    const [deleteModal, setDeleteModal] = useState(false);

    const tryDeleteJobHandler = () => {
        setDeleteModal(true);
    };

    const deleteModalHandler = () => {
        setDeleteModal(!deleteModal);
    };

    const deleteJobHandler = async () => {
        try {
            setLoading(true);
            const response = await deleteJob(token, detailedJob._id);
            const jobDate = detailedJob.scheduledDate.split('T')[0];
            if (response) setActiveDates(response.activeDates);
            if (jobDate === currentDate) {
                const dailyJobsResponse = await fetchJobsByDate(
                    token,
                    currentDate
                );
                setDailyJobs(dailyJobsResponse.jobs);
            }
            setLoading(false);
            deleteModalHandler();
            setNotification(
                `Job "${detailedJob.jobTitle}" was deleted succesfully!`
            );
            setDetailedJob(null);
        } catch (error) {
            setError(
                'There was an error deleting the job. Try again later or contact the administrator!'
            );
        }
    };

    const handleJobClick = async (job) => {
        try {
            let selectedJob = null;
            setLoading(true);
            if (!job._id) {
                selectedJob = await getJob(job);
            } else {
                selectedJob = job;
            }
            if (selectedJob) setEdittingEvent(true);
            const employeesResponse = await fetchEmployees(token);
            const assignedEmployees = employeesResponse.data.employees.map(
                (employee) => {
                    if (
                        selectedJob.assignedTo.some(
                            (empl) => employee._id === empl._id
                        )
                    )
                        return { ...employee, selected: true };
                    return employee;
                }
            );
            setJob({
                ...selectedJob,
                deletedImages: [],
                scheduledDate: selectedJob.scheduledDate.split('T')[0],
            });
            setLoading(false);
            setEmployees(assignedEmployees);
            openModalHandler();
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        async function getJobs() {
            try {
                setLoading(true);
                const activeDatesResponse = await fetchActiveDates(token);
                setActiveDates(activeDatesResponse.activeDates);

                const employeesResponse = await fetchEmployees(token);
                setFetchedEmployees(
                    employeesResponse.data.employees.map((employee) => ({
                        ...employee,
                        selected: false,
                    }))
                );
                const dailyJobsResponse = await fetchJobsByDate(
                    token,
                    new Date().toISOString().split('T')[0]
                );
                setDailyJobs(dailyJobsResponse.jobs);
                setLoading(false);
            } catch (error) {
                console.log('error', error);
            }
        }
        getJobs();
    }, [token]);

    useEffect(() => {
        setEmployees(fetchedEmployees);
    }, [fetchedEmployees]);

    const handleClose = (edittingJob = false) => {
        setJob((oldJob) => ({
            ...oldJob,
            images: [],
        }));
        if (!edittingJob) {
            setJob(initialState);
        }
        setEdittingEvent(false);
        setEmployees(fetchedEmployees);
        setSelected(null);
        setOpen(false);
    };

    const editJobHandler = async () => {
        try {
            const selectedUserIds = [];

            setLoading(true);
            employees.forEach((user) => {
                if (user.selected) {
                    selectedUserIds.push(user._id);
                }
            });
            if (selectedUserIds.length === 0) {
                setError('Job should be assigned to at least one employee');
                const err = new Error();
                err.response = {
                    data: {
                        message:
                            'Job should be assigned to at least one employee',
                    },
                };
                throw err;
            }

            setJob((oldJob) => ({
                ...oldJob,
                assignedTo: selectedUserIds,
                // images: selectedFile ? [selectedFile] : [],
            }));

            const response = await updateJob(
                token,
                job.id,
                job,
                selectedUserIds
            );
            if (response) setActiveDates(response.activeDates);
            const dailyJobsResponse = await fetchJobsByDate(token, currentDate);
            setDailyJobs(dailyJobsResponse.jobs);
            if (response && showSearchResults) {
                handleSearch();
            }
            // if (selectedFile) {
            //     const res = await getJobComments(token, response.job._id);
            //     setJobComments(res.data.data.jobComments);
            //     setSelectedFile(null);
            // }
            if (
                editDetailedJob &&
                detailedJob &&
                response.job._id === detailedJob._id
            ) {
                setDetailedJob(response.job);
            }
            setEditDetailedJob(false);
            let closeByJobsResponse = await getCloseByJobs({
                postalCode: job.postalCode,
                currentJob: job._id,
            });
            setLoading(false);
            if (closeByJobsResponse.data.results > 0) {
                let editedJobs = [];
                closeByJobsResponse.data.jobs.forEach((job) => {
                    let date = job.scheduledDate.split('T')[0];
                    if (job.time) {
                        date = date + 'T' + job.time;
                    }
                    editedJobs.push({
                        id: job._id,
                        start: date,
                        end: dayjs(date)
                            .add(job.duration, 'hour')
                            .format('YYYY-MM-DDTHH:mm'),
                        ...job,
                    });
                });
                setSearchedJobs(editedJobs);
                setCloseByModal(true);
            }
            setError(null);
            handleClose(true);
        } catch (error) {
            setLoading(false);
            setError(error.response.data.message);
        }
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);
            employees.forEach((user) => {
                if (user.selected) {
                    job.assignedTo.push(user._id);
                }
            });
            if (job.assignedTo.length === 0) {
                const err = new Error();
                err.response = {
                    data: {
                        message:
                            'Job should be assigned to at least one employee',
                    },
                };
                throw err;
            }
            if (!job.scheduledDate) {
                setError('no date');
                const err = new Error();
                err.response = {
                    data: {
                        message: 'Please select the date',
                    },
                };
                throw err;
            }
            let response = await createJob(token, { ...job });
            if (response) setActiveDates(response.data.data.activeDates);
            if (currentDate === job.scheduledDate) {
                const dailyJobsResponse = await fetchJobsByDate(
                    token,
                    currentDate
                );
                setDailyJobs(dailyJobsResponse.jobs);
            }

            let closeByJobsResponse = await getCloseByJobs({
                postalCode: job.postalCode,
                currentJob: response.data.data.job._id,
            });
            setLoading(false);
            if (closeByJobsResponse.data.results > 0) {
                let editedJobs = [];
                closeByJobsResponse.data.jobs.forEach((job) => {
                    let date = job.scheduledDate.split('T')[0];
                    if (job.time) {
                        date = date + 'T' + job.time;
                    }
                    editedJobs.push({
                        id: job._id,
                        start: date,
                        end: dayjs(date)
                            .add(job.duration, 'hour')
                            .format('YYYY-MM-DDTHH:mm'),
                        ...job,
                    });
                });
                setSearchedJobs(editedJobs);
                setCloseByModal(true);
            }
            setError(null);
            handleClose();
        } catch (error) {
            setLoading(false);
            setError(error.response.data.message);
        }
    };

    const [openImageDialog, setOpenImageDialog] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [closeByModal, setCloseByModal] = useState(false);

    const handleImageClick = (image) => {
        setSelectedImage(image);
        setOpenImageDialog(true);
    };

    const handleDialogClose = () => {
        setOpenImageDialog(false);
    };

    const deleteImageHandler = (image) => {
        setJob((oldJob) => ({
            ...oldJob,
            images: oldJob.images.filter((img) => img !== image),
            deletedImages: [...oldJob.deletedImages, image],
        }));
    };

    const confirmationDialogModalHandler = () => {
        setCloseByModal(!closeByModal);
    };

    // close by jobs

    const statusOptions = [
        { value: 'new', label: 'New' },
        { value: 'completed', label: 'Completed' },
        { value: 'partArrived', label: 'Part Arrived' },
    ];

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        );
    };

    const handleStatusChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedStatus(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        );
    };

    const ITEM_HEIGHT = 40;
    const ITEM_PADDING_TOP = 1;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const handleFilterJobs = () => {
        async function getJobs() {
            setLoading(true);
            const activeDatesResponse = await fetchActiveDates(token, {
                ids: personName,
                statuses: selectedStatus,
            });
            // CHECK SEARCH RESULTS
            setActiveDates(activeDatesResponse.activeDates);
            const dailyJobsResponse = await fetchJobsByDate(
                token,
                currentDate,
                {
                    ids: personName,
                    statuses: selectedStatus,
                }
            );
            setDailyJobs(dailyJobsResponse.jobs);
            setLoading(false);
        }
        getJobs();
    };

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [searchText, setSearchText] = useState('');

    const searchTextHandler = (event) => {
        setSearchText(event.target.value);
    };

    const [searchedJobs, setSearchedJobs] = useState(null);
    const [showSearchResults, setShowSearchResults] = useState(false);

    const handleSearch = (id = null) => {
        async function getJobs() {
            setLoading(true);
            const activeDatesResponse = await fetchActiveDates(token, {
                searchText: searchText,
                ids: personName,
                statuses: selectedStatus,
            });
            setActiveDates(activeDatesResponse.activeDates);
            setDailyJobs(activeDatesResponse.jobs);
            setLoading(false);
            setShowSearchResults(true);
        }
        getJobs();
    };

    const [openSearchResults, setOpenSearchResults] = useState(false);

    const handleToggleSearchResultsModal = () => {
        setOpenSearchResults(!openSearchResults);
    };

    const priorityColors = ['#048282', '#eba434', '#941515'];

    const styles = {
        avatarContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
        },
        avatar: {
            width: 24,
            height: 24,
            mb: 0.5,
            mr: 0.5,
            ml: -1,
        },
    };

    const [notification, setNotification] = useState(null);

    // upload image
    const [selectedFile, setSelectedFile] = useState(null);

    const [imagesToUpload, setImagesToUpload] = useState([]);

    const handleFileInputChange = (event) => {
        const files = Array.from(event.target.files);
        if (files.length > 0) {
            setImagesToUpload((oldImages) => [...oldImages, ...files]);
        }
        setSelectedFile(files);
        // const file = event.target.files[0];
        // if (file) {
        //     setSelectedFile(file);
        //     setDetailedJob((oldJob) => ({
        //         ...oldJob,
        //         images: [...oldJob.images, file],
        //     }));
        //     setJob((oldJob) => ({
        //         ...oldJob,
        //         images: [...oldJob.images, file],
        //     }));
        // }
    };

    const handleUploadButtonClick = async () => {
        // await editJobHandler();

        const res = await addImageOrComment(token, {
            jobId: detailedJob._id,
            images: imagesToUpload,
            createdBy: currentUser._id,
        });

        setJobComments((oldComments) => [
            ...oldComments,
            ...res.data.data.jobComments.map((comment) => ({
                ...comment,
                createdBy: {
                    _id: comment.createdBy,
                    name: currentUser.name,
                    surname: currentUser.surname,
                },
            })),
        ]);
        setSelectedFile(null);
    };

    const detailedJobClickHandler = useCallback(
        (job) => {
            const assignedEmployees = fetchedEmployees.map((employee) => {
                if (job.assignedTo.some((empl) => employee._id === empl._id))
                    return { ...employee, selected: true };
                return employee;
            });
            // setJobComments((oldJobs) => []);
            setEmployees(assignedEmployees);
            setSelectedFile(null);
            setDetailedJob(job);
            setJob(job);
        },
        [fetchedEmployees]
    );

    const [jobComments, setJobComments] = useState([]);

    const handleCustomButtonClick = async () => {
        setSearchText('');
        setPersonName([]);
        setSelectedStatus([]);
        setLoading(true);
        const activeDatesResponse = await fetchActiveDates(token);
        setActiveDates(activeDatesResponse.activeDates);
        setDailyJobs(activeDatesResponse.jobs);
        setShowSearchResults(false);
        setLoading(false);
    };

    return (
        <Box m="20px">
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: 900,
                }}
                open={loading}
                onClick={handleClose}
            >
                <CircularProgress style={{ color: '#048282' }} />
            </Backdrop>
            <ErrorAlert
                style={{ marginBottom: '10px' }}
                error={error}
                onClose={setError}
                severity="error"
            />
            <ErrorAlert
                style={{ marginBottom: '10px' }}
                error={notification}
                onClose={setNotification}
                severity="success"
            />
            <SearchList
                open={openSearchResults}
                toggle={handleToggleSearchResultsModal}
                results={searchedJobs}
                onClick={handleJobClick}
            />
            {/* FILTERS */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 1,
                }}
            >
                <Box
                    display="flex"
                    borderRadius="3px"
                    sx={{ ml: 2 }}
                    backgroundColor={colors.primary[500]}
                >
                    <InputBase
                        sx={{ ml: 2, flex: 1 }}
                        placeholder="Search"
                        value={searchText}
                        onChange={searchTextHandler}
                    />
                    <IconButton
                        type="button"
                        sx={{ p: 1 }}
                        onClick={() => handleSearch()}
                    >
                        <SearchIcon />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        marginBottom: 1,
                    }}
                >
                    <FormControl variant="standard" sx={{ mr: 2, width: 150 }}>
                        <InputLabel id="demo-multiple-checkbox-label">
                            Status
                        </InputLabel>
                        <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={selectedStatus}
                            onChange={handleStatusChange}
                            renderValue={(selected) =>
                                statusOptions
                                    .filter((item) =>
                                        selectedStatus.includes(item.value)
                                    )
                                    .map((item) => `${item.label}`)
                                    .join(', ')
                            }
                            MenuProps={MenuProps}
                        >
                            {statusOptions.map((item) => (
                                <MenuItem key={item.value} value={item.value}>
                                    <Checkbox
                                        checked={selectedStatus.includes(
                                            item.value
                                        )}
                                    />
                                    <ListItemText primary={`${item.label}`} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {currentUser.role === 'admin' && (
                        <FormControl
                            variant="standard"
                            sx={{ mr: 2, width: 150 }}
                        >
                            <InputLabel id="demo-multiple-checkbox-label">
                                Employee
                            </InputLabel>
                            <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                value={personName}
                                onChange={handleChange}
                                renderValue={(selected) =>
                                    fetchedEmployees
                                        .filter((item) =>
                                            personName.includes(item._id)
                                        )
                                        .map(
                                            (item) =>
                                                `${item.name} ${item.surname}`
                                        )
                                        .join(', ')
                                }
                                MenuProps={MenuProps}
                            >
                                {fetchedEmployees.map((item) => (
                                    <MenuItem key={item._id} value={item._id}>
                                        <Checkbox
                                            checked={personName.includes(
                                                item._id
                                            )}
                                        />
                                        <ListItemText
                                            primary={`${item.name} ${item.surname}`}
                                        />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        startIcon={<FilterListOutlinedIcon />}
                        onClick={handleFilterJobs}
                    >
                        Filter
                    </Button>
                </Box>
            </Box>
            <Box
                flex="1 1 100%"
                ml="15px"
                sx={{ width: '100%', height: '88vh', overflow: 'auto' }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        height: 'fit-content',
                    }}
                    flex="1 1 100%"
                >
                    <Box
                        flex="1 1 100%"
                        sx={{ marginRight: 2, backgroundColor: '#f5f5f5' }}
                    >
                        <FullCalendar
                            ref={calendarRef}
                            height="48vh"
                            hiddenDays={[]}
                            plugins={[dayGridPlugin, interactionPlgin]}
                            headerToolbar={{
                                left: 'prev,next',
                                center: 'title',
                                right: 'today customButton',
                            }}
                            customButtons={{
                                customButton: {
                                    text: 'All Jobs',
                                    click: handleCustomButtonClick,
                                },
                            }}
                            initialView="dayGridMonth"
                            editable={true}
                            selectable={true}
                            selectMirror={true}
                            dayMaxEvents={true}
                            select={handleDateClick}
                            eventContent={eventContent}
                            events={activeDates}
                            timeZone="local"
                        />
                    </Box>
                    <Box flex="1 1 100%" sx={{ backgroundColor: '#f5f5f5' }}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                position: 'relative',
                            }}
                        >
                            <Typography
                                variant="h4"
                                gutterBottom
                                style={{
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    paddingTop: 8,
                                }}
                            >
                                {showSearchResults
                                    ? 'Search Results'
                                    : `${
                                          formatDateTime(currentDate).fullDate
                                      } - ${
                                          dailyJobs ? dailyJobs.length : 0
                                      } Job(s)`}
                            </Typography>
                            <IconButton
                                aria-label="close"
                                size="large"
                                sx={{
                                    position: 'absolute',
                                    right: 10,
                                    top: -5,
                                    color: '#048282',
                                }}
                                onClick={() => {
                                    newJobHandler();
                                }}
                            >
                                <AddCircleOutlineIcon fontSize="inherit" />
                            </IconButton>
                        </Box>
                        <JobsList
                            dailyJobs={dailyJobs}
                            detailedJobClickHandler={detailedJobClickHandler}
                        />
                    </Box>
                </Box>
                {/* JOB VIEW */}
                {detailedJob && (
                    <Box
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            height: '50vh',
                            overflow: 'auto',
                        }}
                    >
                        <Box
                            sx={{
                                width: 'inline-block',
                                minWidth: '50%',
                                maxWidth: '70%',
                                backgroundColor: '#f5f5f5',
                                marginTop: 1,
                            }}
                        >
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Avatar
                                    sx={{
                                        ...styles.avatar,
                                        bgcolor: currentUser.avatarColor,
                                        margin: 1,
                                    }}
                                >
                                    {detailedJob.createdBy
                                        ? Array.from(
                                              detailedJob.createdBy.name
                                          )[0] +
                                          Array.from(
                                              detailedJob.createdBy.surname
                                          )[0]
                                        : Array.from(currentUser.name)[0] +
                                          Array.from(currentUser.surname)[0]}
                                </Avatar>
                                <Box>
                                    <Box sx={{ fontWeight: 'bold', mt: 0.2 }}>
                                        {detailedJob.createdBy
                                            ? detailedJob.createdBy.name +
                                              ' ' +
                                              detailedJob.createdBy.surname
                                            : 'jo'}
                                    </Box>
                                    <Box sx={{ color: 'gray' }}>
                                        {
                                            formatDateTime(
                                                detailedJob.scheduledDate
                                            ).fullDate
                                        }{' '}
                                        {detailedJob.time}
                                    </Box>
                                </Box>
                                <Box sx={{ ml: 'auto', mr: 2, mt: 1 }}>
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            editDetailedJobHandler(detailedJob);
                                        }}
                                    >
                                        <EditOutlinedIcon fontSize="inherit" />
                                    </IconButton>
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={tryDeleteJobHandler}
                                    >
                                        <DeleteOutlineIcon fontSize="inherit" />
                                    </IconButton>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    fontWeight: 'bold',
                                }}
                            >
                                <TitleIcon
                                    sx={{
                                        mt: 0,
                                        ml: 1,
                                        mr: 1,
                                        width: 15,
                                    }}
                                />
                                {detailedJob.jobTitle}
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: priorityColors[
                                        detailedJob.priority ?? 0
                                    ],
                                }}
                            >
                                <LowPriorityOutlinedIcon
                                    sx={{
                                        mt: 0,
                                        ml: 1,
                                        mr: 1,
                                        width: 15,
                                    }}
                                />
                                {detailedJob.priority === 0
                                    ? 'Low'
                                    : detailedJob.priority === 1
                                    ? 'Medium'
                                    : detailedJob.priority === 2
                                    ? 'High'
                                    : 'Low'}{' '}
                                Priority
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <GroupOutlinedIcon
                                    sx={{
                                        mt: 0,
                                        ml: 1,
                                        mr: 1,
                                        width: 15,
                                    }}
                                />
                                {detailedJob.assignedTo.map(
                                    (user) => `${user.name} ${user.surname}, `
                                )}
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <LocationOnOutlinedIcon
                                    sx={{
                                        mt: 0,
                                        ml: 1,
                                        mr: 1,
                                        width: 15,
                                    }}
                                />
                                {detailedJob.address}
                                <HomeOutlinedIcon
                                    sx={{
                                        mt: 0,
                                        ml: 1,
                                        mr: 1,
                                        width: 15,
                                    }}
                                />
                                <Box style={{ textTransform: 'uppercase' }}>
                                    {detailedJob.postalCode}
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <ViewTimelineOutlined
                                    sx={{
                                        mt: 0,
                                        ml: 1,
                                        mr: 1,
                                        width: 15,
                                    }}
                                />
                                Status: {formatStatus(detailedJob.status)}
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                }}
                            >
                                <AttachmentIcon
                                    sx={{
                                        mt: 0,
                                        ml: 1,
                                        mr: 1,
                                        width: 15,
                                    }}
                                />
                                {/* Input component styled as an icon button */}
                                {!selectedFile && (
                                    <>
                                        <input
                                            type="file"
                                            accept="image/*"
                                            id="image-upload-input"
                                            style={{ display: 'none' }}
                                            onChange={handleFileInputChange}
                                            multiple // Confirming multiple selection
                                        />
                                        <label htmlFor="image-upload-input">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                component="span"
                                                size="small"
                                                startIcon={<Folder />}
                                            >
                                                Browse
                                            </Button>
                                        </label>
                                    </>
                                )}
                                {selectedFile && (
                                    <>
                                        <Box sx={{ mr: 1 }}>
                                            {selectedFile &&
                                                (() => {
                                                    // Join all file names with commas
                                                    const names = selectedFile
                                                        .map(
                                                            (file) => file.name
                                                        )
                                                        .join(', ');

                                                    // Check if total length exceeds 50 characters
                                                    return names.length > 50
                                                        ? `${names.slice(
                                                              0,
                                                              50
                                                          )}...`
                                                        : names;
                                                })()}
                                        </Box>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            onClick={handleUploadButtonClick}
                                        >
                                            Upload Image
                                        </Button>
                                    </>
                                )}
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    bgcolor: '#f5f5f5',
                                    mt: 1,
                                }}
                            >
                                <DescriptionOutlinedIcon
                                    sx={{
                                        mt: 0,
                                        ml: 1,
                                        mr: 1,
                                        width: 15,
                                    }}
                                />
                                <pre
                                    style={{
                                        maxWidth: '100%', // Set a maximum width
                                        whiteSpace: 'pre-wrap', // Wrap text to the next line
                                        marginTop: 0,
                                    }}
                                >
                                    {detailedJob.jobDescription}
                                </pre>
                            </Box>

                            <Box>
                                <JobImageOrComment
                                    jobComments={jobComments}
                                    setJobComments={setJobComments}
                                    jobId={detailedJob._id}
                                />
                            </Box>
                        </Box>
                    </Box>
                )}
                <ConfirmationDialog
                    open={deleteModal}
                    handleOpen={deleteModalHandler}
                    handleAgree={deleteJobHandler}
                    title="Are you sure?"
                    message="The job will be deletd and you will not be able to undo this action!"
                />
                <AddJobForm
                    openForm={open}
                    loading={loading}
                    onClose={handleClose}
                    job={job}
                    handleInputChange={handleInputChange}
                    employees={employees}
                    handleToggle={handleToggle}
                    edittingEvent={edittingEvent}
                    editJobHandler={editJobHandler}
                    handleSubmit={handleSubmit}
                    error={error}
                    setError={setError}
                    handleImageClick={handleImageClick}
                    deleteImageHandler={deleteImageHandler}
                    openImageDialog={openImageDialog}
                    handleDialogClose={handleDialogClose}
                    selectedImage={selectedImage}
                />
                <ConfirmationDialog
                    open={closeByModal}
                    handleOpen={confirmationDialogModalHandler}
                    handleAgree={handleToggleSearchResultsModal}
                    title={`${
                        searchedJobs && searchedJobs.length
                    } Close By Jobs found ready to reschedule`}
                    message="Would you like to check these jobs?"
                />
            </Box>
        </Box>
    );
};

export default Dashboard;
