// 08
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    Box,
    List,
    ListItem,
    Divider,
    ListItemText,
    ListItemAvatar,
    Avatar,
    TextField,
    Button,
    Dialog,
    DialogContent,
    IconButton,
} from '@mui/material';
import {
    addComment,
    deleteJobComment,
    getJobComments,
    updateJobComment,
} from '../../../api/jobComments-api';

import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import formatDateTime from '../../../utils/dateFormatter';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import ConfirmationDialog from '../../../components/custom/CustomConfirmationDialog';

const JobComments = (props) => {
    const styles = {
        avatarContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
        },
        avatar: {
            width: 40,
            height: 40,
            mr: 1,
        },
    };

    const [comment, setComment] = useState('');
    const { jobComments, setJobComments } = props;

    const currentUser = useSelector((state) => state.auth.currentUser);
    const token = useSelector((state) => state.auth.token);

    const handleInputChange = (event) => {
        setComment(event.target.value);
    };

    const handleAddComment = async () => {
        try {
            const res = await addComment(token, {
                jobId: props.jobId,
                createdBy: currentUser._id,
                note: comment,
            });
            setJobComments((oldComments) => [
                ...oldComments,
                {
                    ...res.data.data.newJobComment,
                    createdBy: {
                        _id: res.data.data.newJobComment.createdBy,
                        name: currentUser.name,
                        surname: currentUser.surname,
                    },
                },
            ]);
            setComment('');
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        async function fetchJobComments() {
            const res = await getJobComments(token, props.jobId);
            setJobComments(res.data.data.jobComments);
        }
        fetchJobComments();
    }, [token, props.jobId, setJobComments]);

    const [openImageDialog, setOpenImageDialog] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const handleDialogClose = () => {
        setOpenImageDialog(false);
        setSelectedImage(null);
    };

    const handleImageClick = (selectedImageUri) => {
        setSelectedImage(selectedImageUri);
        setOpenImageDialog(true);
    };

    // Delete comment
    const [jobToDelete, setJobToDelete] = useState({});

    const prepareDeleteHandler = (id, index) => {
        setJobToDelete({ id, index });
        setConfirmationModalOpen(true);
    };

    const handleDeleteJobComment = async () => {
        try {
            await deleteJobComment(token, jobToDelete.id);
            const newComments = [
                ...jobComments.slice(0, jobToDelete.index),
                ...jobComments.slice(jobToDelete.index + 1),
            ];
            setJobComments(newComments);
            setConfirmationModalOpen(false);
        } catch (error) {
            console.log(error);
        }
    };

    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

    // Editting comment
    const [edittingComment, setEdittingComment] = useState(null);
    const [updatedComment, setUpdatedComment] = useState(null);

    const prepareEditCommentHandler = (id, comment, index) => {
        setUpdatedComment(comment);
        setEdittingComment({ id, index });
    };

    const cancelEditHandler = () => {
        setUpdatedComment(null);
        setEdittingComment(null);
    };

    const handleEditCommentChange = (event) => {
        setUpdatedComment(event.target.value);
    };

    const editCommentHandler = async () => {
        try {
            await updateJobComment(token, edittingComment.id, updatedComment);
            // Create a shallow copy of the array
            const updatedComments = [...jobComments];

            // Update the specific object at the given index
            updatedComments[edittingComment.index] = {
                ...updatedComments[edittingComment.index],
                note: updatedComment,
            };

            // Update the state with the new array
            setJobComments(updatedComments);
            setEdittingComment(null);
            setUpdatedComment(null);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flex: 1,
                height: '100%',
            }}
        >
            <List
                sx={{
                    width: '100%',
                    bgcolor: '#f5f5f5',
                    overflow: 'auto',
                    position: 'relative',
                }}
            >
                {jobComments.length !== 0 ? (
                    jobComments.map((comment, index) => (
                        <React.Fragment key={comment._id}>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar
                                        sx={{
                                            ...styles.avatar,
                                        }}
                                    >
                                        {Array.from(comment.createdBy.name)[0] +
                                            Array.from(
                                                comment.createdBy.surname
                                            )[0]}
                                    </Avatar>
                                </ListItemAvatar>
                                <Box sx={{ width: '75%' }}>
                                    <ListItemText
                                        primary={`${comment.createdBy.name} ${comment.createdBy.surname}`}
                                        secondary={`${
                                            formatDateTime(comment.createdAt)
                                                .fullDateTime
                                        }`}
                                    ></ListItemText>
                                    {comment.note.startsWith('job-') ? (
                                        <img
                                            src={`http://68.183.202.204/api/v1/jobs/images/${comment.note}`}
                                            alt={`${comment.note}`}
                                            width="auto"
                                            height={200}
                                            onClick={() => {
                                                handleImageClick(comment.note);
                                            }}
                                        />
                                    ) : (
                                        <Box>
                                            {edittingComment &&
                                            edittingComment.id ===
                                                comment._id ? (
                                                <Box
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                    }}
                                                >
                                                    <TextField
                                                        id="standard-multiline-static"
                                                        label="Edit Comment"
                                                        value={updatedComment}
                                                        onChange={
                                                            handleEditCommentChange
                                                        }
                                                        multiline
                                                        fullWidth
                                                        rows={3}
                                                        variant="outlined"
                                                    />
                                                    <Box>
                                                        <IconButton
                                                            aria-label="close"
                                                            color="inherit"
                                                            size="small"
                                                            onClick={
                                                                editCommentHandler
                                                            }
                                                        >
                                                            <SaveIcon fontSize="inherit" />
                                                        </IconButton>
                                                        <IconButton
                                                            aria-label="close"
                                                            color="inherit"
                                                            size="small"
                                                            onClick={
                                                                cancelEditHandler
                                                            }
                                                        >
                                                            <CloseIcon fontSize="inherit" />
                                                        </IconButton>
                                                    </Box>
                                                </Box>
                                            ) : (
                                                <pre
                                                    style={{
                                                        maxWidth: '100%', // Set a maximum width
                                                        whiteSpace: 'pre-wrap', // Wrap text to the next line
                                                    }}
                                                >
                                                    {comment.note}
                                                </pre>
                                            )}
                                        </Box>
                                    )}
                                </Box>
                                <Box sx={{ ml: 'auto', mr: 2, mt: 1 }}>
                                    {!comment.note.startsWith('job-') && (
                                        <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                                prepareEditCommentHandler(
                                                    comment._id,
                                                    comment.note,
                                                    index
                                                );
                                            }}
                                        >
                                            <EditOutlinedIcon fontSize="inherit" />
                                        </IconButton>
                                    )}

                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            prepareDeleteHandler(
                                                comment._id,
                                                index
                                            );
                                        }}
                                    >
                                        <DeleteOutlineIcon fontSize="inherit" />
                                    </IconButton>
                                </Box>
                            </ListItem>
                            <Divider variant="inset" component="li" />
                        </React.Fragment>
                    ))
                ) : (
                    <Box>There are no comments for this job!</Box>
                )}
            </List>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    backgroundColor: '#f5f5f5',
                }}
            >
                <Avatar
                    sx={{
                        ...styles.avatar,
                    }}
                >
                    {Array.from(currentUser.name)[0] +
                        Array.from(currentUser.surname)[0]}
                </Avatar>
                <TextField
                    id="standard-multiline-static"
                    label="Add Comment"
                    value={comment}
                    onChange={handleInputChange}
                    multiline
                    fullWidth
                    rows={3}
                    variant="outlined"
                />
                <Button
                    onClick={handleAddComment}
                    variant="contained"
                    color="primary"
                    disabled={comment.length === 0}
                    sx={{
                        width: 'fit-content',
                        height: '50px',
                        ml: 1,
                        minWidth: 'fit-content',
                    }}
                    endIcon={<SendOutlinedIcon />}
                >
                    Add Comment
                </Button>
            </Box>
            <Dialog open={openImageDialog} onClose={handleDialogClose}>
                <DialogContent>
                    <img
                        src={`http://68.183.202.204/api/v1/jobs/images/${selectedImage}`}
                        alt="Full Size"
                        style={{ width: '100%' }}
                    />
                </DialogContent>
            </Dialog>
            <ConfirmationDialog
                open={confirmationModalOpen}
                handleOpen={() => {
                    setConfirmationModalOpen(!confirmationModalOpen);
                }}
                handleAgree={handleDeleteJobComment}
                title="Are you sure?"
                message="The comment will be deleted and you will not be able to undo this action!"
            />
        </Box>
    );
};

export default JobComments;
