/*
 ** @author: Argert Boja
 ** @date: 12/07/2023
 ** @version: 1.0.0
 ** @description: Login form used to authenticate and authorize a user
 */
import React, { useState, useEffect, useCallback } from 'react';
import { authActions } from '../../../store/auth-slice';
import { signIn, autoSignIn } from '../../../api/auth';
import { useDispatch } from 'react-redux';

// MUI
import {
    IconButton,
    Box,
    useTheme,
    Collapse,
    Alert,
    Button,
    Container,
    Typography,
} from '@mui/material';

// Icons
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CloseIcon from '@mui/icons-material/Close';

// Custom components
import QuestionActionText from '../../../components/custom/QuestionActionText';
import TextFieldWithIcon from '../../../components/custom/TextFieldWithIcon';
import CustomCheckbox from '../../../components/custom/CustomCheckbox';
import SocialLogin from '../../../components/custom/SocialLogin';

const LoginForm = (props) => {
    const dispatch = useDispatch();
    const theme = useTheme();

    // state management
    // state variables
    const [user, setUser] = useState({
        username: '',
        password: '',
    });
    const [rememberMe, setRememberMe] = useState(true);
    const [initialRememberMe, setInitialRememberMe] = useState(true);
    const [error, setError] = useState(null);
    const [hidePassword, setHidePassword] = useState(true);
    const [isUsernameChanged, setIsUsernameChanged] = useState(false);
    const [isPasswordChanged, setIsPasswordChanged] = useState(false);

    // Autocomplete input fields if user had previously clicked on remember me checkbox

    const autoLogin = useCallback(async () => {
        try {
            const storedToken = localStorage.getItem('token');
            const response = await autoSignIn(localStorage.getItem('token'));
            dispatch(
                authActions.authenticate({
                    token: storedToken,
                    user: response.data.user,
                    rememberMe,
                    username: user.username,
                    password: user.password,
                })
            );
        } catch (error) {
            console.log('error', error);
        }
    }, [dispatch, rememberMe, user.username, user.password]);

    useEffect(() => {
        const storedUsername = localStorage.getItem('username');
        const storedPassword = localStorage.getItem('password');

        if (storedUsername) {
            setUser((prevUser) => ({
                ...prevUser,
                username: isUsernameChanged
                    ? prevUser.username
                    : storedUsername,
                password: isPasswordChanged
                    ? prevUser.password
                    : storedPassword,
            }));

            setInitialRememberMe(true);
        }

        if (localStorage.getItem('token')) {
            autoLogin();
        }
    }, [autoLogin, isUsernameChanged, isPasswordChanged]);

    useEffect(() => {
        setRememberMe(initialRememberMe);
    }, [initialRememberMe]);

    // handlers
    // handle input changes
    const handleInputChange = (name, value) => {
        if (name === 'username') setIsUsernameChanged(true);
        else if (name === 'password') setIsPasswordChanged(true);
        setUser((oldUser) => ({
            ...oldUser,
            [name]: value,
        }));
    };

    // show / hide password
    const hidePasswordHandler = () => {
        setHidePassword((prevHidePassword) => !prevHidePassword);
    };

    // remember me checkobox
    const checkboxHandler = () => {
        setRememberMe((prevState) => !prevState);
    };

    // sign in user
    async function signInHandler(username, password) {
        try {
            const res = await signIn({
                username,
                password,
            });
            dispatch(
                authActions.authenticate({
                    token: res.token,
                    user: res.data.user,
                    rememberMe,
                    username: user.username,
                    password: user.password,
                })
            );

            setError(null);
            return res;
        } catch (error) {
            // show error message if user cannot signin
            setError(error.response.data.message);
        }
    }

    // submit login handler
    const handleLoginSubmit = (event) => {
        event.preventDefault();
        signInHandler(user.username, user.password);
    };

    return (
        <>
            <Typography
                fontSize={30}
                color={theme.palette.primary.main}
                fontWeight="bold"
            >
                Welcome Back
            </Typography>
            <QuestionActionText
                question="Don't have an account?"
                actionText="Register now!"
                action={() => props.changeForm('register')}
            />
            <Container
                maxWidth="xs"
                style={{
                    height: '70%',
                    borderTopLeftRadius: '20px',
                    borderTopRightRadius: '20px',
                }}
            >
                <form onSubmit={handleLoginSubmit}>
                    <TextFieldWithIcon
                        label="Username"
                        name="username"
                        style={{ marginTop: '30px' }}
                        required
                        value={user.username}
                        autoFocus
                        onChange={handleInputChange}
                        icon={<PermIdentityOutlinedIcon />}
                    />
                    <TextFieldWithIcon
                        label="Password"
                        name="password"
                        required
                        style={{ marginTop: '30px' }}
                        value={user.password}
                        onChange={handleInputChange}
                        hidePassword={hidePassword}
                        icon={
                            <IconButton
                                onClick={hidePasswordHandler}
                                style={{
                                    color: theme.palette.primary.main,
                                }}
                                edge="end"
                            >
                                {hidePassword ? (
                                    <VisibilityOutlinedIcon />
                                ) : (
                                    <VisibilityOffOutlinedIcon />
                                )}
                            </IconButton>
                        }
                    />
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <CustomCheckbox
                            label="REMEMBER ME"
                            value={rememberMe}
                            onChange={checkboxHandler}
                        />
                        <QuestionActionText
                            question="Forgot your password?"
                            questionAction={() =>
                                props.changeForm('forgotPassword')
                            }
                            questionIsAction
                        />
                    </Box>
                    <Collapse in={error !== null}>
                        <Alert
                            severity="error"
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setError(null);
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                        >
                            {error}
                        </Alert>
                    </Collapse>

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        style={{ marginTop: '10px' }}
                        startIcon={<LockOutlinedIcon />}
                    >
                        Sign In
                    </Button>
                </form>
                <SocialLogin />
            </Container>
        </>
    );
};

export default LoginForm;
