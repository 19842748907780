/*
 ** @date: 23/07/2023
 ** @version: 1.0.0
 ** @author: argertboja@gmail.com
 ** @description: This component creates a custom Select component
 ********************************
 ** @Expected Props:
 ******** props.value: Integer / String => Current value of Select
 ******** props.handleInputChange: Function => Function which is used to handle changes in select box
 ******** props.label: String => Label of Select
 ******** props.name: String => Name of the Select box
 ******** props.options: Object => JSON with value and label for each option of the select
 */
import React from 'react';
import { Select, MenuItem } from '@mui/material';

const availableOptions = {
    new: [
        { value: 'new', label: 'NEW' },
        { value: 'completed', label: 'COMPLETED' },
        { value: 'partArrived', label: 'PART ARRIVED' },
        // { value: 'partToOrder', label: 'PART TO ORDER' },
        // { value: 'proposalSent', label: 'PROPOSAL SENT' },
        // { value: 'reschedule', label: 'RESCHEDULE' },
        // { value: 'rentedOut', label: 'RENTED OUT' },
        // { value: 'canceled', label: 'CANCELED' },
    ],
    completed: [
        { value: 'new', label: 'NEW' },
        { value: 'completed', label: 'COMPLETED' },
        { value: 'partArrived', label: 'PART ARRIVED' },
        // { value: 'invoiced', label: 'INVOICED' },
        // { value: 'reschedule', label: 'RESCHEDULE' },
        // { value: 'canceled', label: 'CANCELED' },
    ],
    // invoiced: [
    //     { value: 'completed', label: 'COMPLETED' },
    //     { value: 'invoiced', label: 'INVOICED' },
    //     { value: 'canceled', label: 'CANCELED' },
    // ],
    // partToOrder: [
    //     { value: 'new', label: 'NEW' },
    //     { value: 'partToOrder', label: 'PART TO ORDER' },
    //     { value: 'partOrdered', label: 'PART ORDERED' },
    //     { value: 'reschedule', label: 'RESCHEDULE' },
    //     { value: 'canceled', label: 'CANCELED' },
    // ],
    // partOrdered: [
    //     { value: 'partToOrder', label: 'PART TO ORDER' },
    //     { value: 'partOrdered', label: 'PART ORDERED' },
    //     { value: 'partArrived', label: 'PART ARRIVED' },
    //     { value: 'canceled', label: 'CANCELED' },
    // ],
    partArrived: [
        //{ value: 'partOrdered', label: 'PART ORDERED' },
        { value: 'partArrived', label: 'PART ARRIVED' },
        { value: 'new', label: 'NEW' },
        { value: 'completed', label: 'COMPLETED' },
        //{ value: 'canceled', label: 'CANCELED' },
    ],
    // proposalSent: [
    //     { value: 'new', label: 'NEW' },
    //     { value: 'proposalSent', label: 'PROPOSAL SENT' },
    //     { value: 'proposalApproved', label: 'PROPOSAL APPROVED' },
    //     { value: 'canceled', label: 'CANCELED' },
    // ],
    // proposalApproved: [
    //     { value: 'proposalSent', label: 'PROPOSAL SENT' },
    //     { value: 'proposalApproved', label: 'PROPOSAL APPROVED' },
    //     { value: 'proposalArrived', label: 'PROPOSAL ARRIVED' },
    //     { value: 'canceled', label: 'CANCELED' },
    // ],
    // proposalArrived: [
    //     { value: 'proposalApproved', label: 'PROPOSAL APPROVED' },
    //     { value: 'proposalArrived', label: 'PROPOSAL ARRIVED' },
    //     { value: 'new', label: 'NEW' },
    //     { value: 'canceled', label: 'CANCELED' },
    // ],
    // reschedule: [
    //     { value: 'new', label: 'NEW' },
    //     { value: 'completed', label: 'COMPLETED' },
    //     { value: 'partToOrder', label: 'PART TO ORDER' },
    //     { value: 'proposalSent', label: 'PROPOSAL SENT' },
    //     { value: 'reschedule', label: 'RESCHEDULE' },
    //     { value: 'rentedOut', label: 'RENTED OUT' },
    //     { value: 'canceled', label: 'CANCELED' },
    // ],
    // rentedOut: [
    //     { value: 'new', label: 'NEW' },
    //     { value: 'rentedOut', label: 'RENTED OUT' },
    //     { value: 'pickUp', label: 'PICK UP' },
    //     { value: 'canceled', label: 'CANCELED' },
    // ],
    // pickUp: [
    //     { value: 'new', label: 'NEW' },
    //     { value: 'rentedOut', label: 'RENTED OUT' },
    //     { value: 'pickUp', label: 'PICK UP' },
    //     { value: 'completed', label: 'COMPLETED' },
    //     { value: 'canceled', label: 'CANCELED' },
    // ],
};

const CustomSelect = (props) => {
    let options = availableOptions[props.value] || availableOptions.new;
    if (props.options) {
        options = props.options;
    }
    return (
        <Select
            labelId="demo-simple-select-standard-label"
            value={props.value}
            onChange={props.onChange}
            label={props.label}
            name={props.name}
        >
            {options.map((option) => (
                <MenuItem
                    key={option.label ? option.value : option}
                    value={option.label ? option.value : option}
                >
                    {option.label ? option.label : option}
                </MenuItem>
            ))}
        </Select>
    );
};

export default CustomSelect;
