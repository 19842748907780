import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Button } from '@mui/material';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import { useDispatch, useSelector } from 'react-redux';
import AddEmployeeForm from './screen_parts/Employees/AddEmployeeForm';
import {
    fetchEmployees,
    addEmployee,
    deleteEmployee,
    updateEmployee,
} from '../api/user-api';
import ErrorAlert from '../components/custom/ErrorAlert';
import { employeesActions } from '../store/user-slice';
import ConfirmationDialog from '../components/custom/CustomConfirmationDialog';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'number',
        numeric: false,
        disablePadding: false,
        label: '#',
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'User Profile',
    },
    {
        id: 'role',
        numeric: false,
        disablePadding: false,
        label: 'Role',
    },
    {
        id: 'assignedJobsCount',
        numeric: true,
        disablePadding: false,
        label: 'Assigned Jobs',
    },
    {
        id: 'completedJobsCount',
        numeric: true,
        disablePadding: false,
        label: 'Completed Jobs',
    },
    {
        id: 'actions',
        numeric: true,
        disablePadding: false,
        label: 'Actions',
    },
];

// Inside the EnhancedTableHead component
const EnhancedTableHead = (props) => {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={{ fontWeight: 'bold' }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc'
                                        ? 'sorted descending'
                                        : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EmployeesScreen = () => {
    const emptyUser = {
        username: '',
        name: '',
        surname: '',
        email: '',
        role: '',
    };

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('assignedJobs');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const token = useSelector((state) => state.auth.token);
    //const [employees, setEmployees] = React.useState([]);
    const [openForm, setOpenForm] = useState(false);
    const [error, setError] = useState(null);
    const [employee, setEmployee] = useState(emptyUser);
    const dispatch = useDispatch();
    const employees = useSelector((state) => state.user.employees);
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [edittingEmployee, setEdittingEmployee] = useState(false);
    const [notification, setNotification] = useState(null);
    const [err, setErrMessage] = useState(null);

    useEffect(() => {
        async function getEmployees() {
            try {
                const res = await fetchEmployees(token);
                dispatch(employeesActions.getEmployees(res.data.employees));
                //setEmployees(res.data.employees);
            } catch (error) {
                setError(error.response.data.message);
            }
        }
        getEmployees();
    }, [dispatch, token]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - employees.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(employees, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
            ),
        [order, orderBy, page, rowsPerPage, employees]
    );

    const onClose = () => {
        setEmployee(emptyUser);
        setEdittingEmployee(false);
        setError(null);
        setOpenForm(false);
    };

    const handleInputChange = (event) => {
        let { name, value } = event.target;
        setEmployee((oldEmployee) => ({
            ...oldEmployee,
            [name]: value,
        }));
    };

    const editEmployeeHandler = async () => {
        try {
            const response = await updateEmployee(token, {
                _id: employee._id,
                name: employee.name,
                surname: employee.surname,
                email: employee.email,
                username: employee.username,
                role: employee.role,
            });
            dispatch(
                employeesActions.updateEmployee(response.data.data.employee)
            );
            setNotification('User was updated successfully!');
            setEdittingEmployee(false);
            setOpenForm(false);
        } catch (error) {
            setError(error.response.data.message);
        }
    };

    const addEmployeeHandler = async () => {
        try {
            const response = await addEmployee(token, { ...employee });
            dispatch(employeesActions.addEmployee(response.data.data.newUser));
            setNotification('User was created successfully!');
            setOpenForm(false);
        } catch (error) {
            setError(error.response.data.message);
        }
    };

    const deleteEmployeeHandler = async () => {
        try {
            await deleteEmployee(token, selectedUser._id);
            dispatch(employeesActions.deleteEmployee(selectedUser._id));
            confirmationDialogModalHandler(null);
            setNotification('User was deleted successfully!');
        } catch (error) {
            setError(error.response.data.message);
        }
    };

    const editUserModalHandler = (selectedUser) => {
        setEdittingEmployee(true);
        setSelectedUser(selectedUser);
        setEmployee({ ...selectedUser });
        setOpenForm(true);
    };

    const confirmationDialogModalHandler = (selectedUser) => {
        setSelectedUser(selectedUser);
        setDeleteModal(!deleteModal);
    };

    const currentUser = useSelector((state) => state.auth.currentUser);

    return (
        <Box sx={{ width: '100%' }}>
            <AddEmployeeForm
                openForm={openForm}
                onClose={onClose}
                employee={employee}
                edittingEmployee={edittingEmployee}
                handleInputChange={handleInputChange}
                error={error}
                setError={setError}
                editEmployeeHandler={editEmployeeHandler}
                addEmployeeHandler={addEmployeeHandler}
            />
            <ConfirmationDialog
                open={deleteModal}
                handleOpen={confirmationDialogModalHandler}
                handleAgree={deleteEmployeeHandler}
                title="Are you sure?"
                message={
                    <span>
                        The user{' '}
                        <strong>{selectedUser && selectedUser.name}</strong>{' '}
                        <strong>{selectedUser && selectedUser.surname}</strong>{' '}
                        will not have access to the system anymore
                    </span>
                }
            />
            <Paper sx={{ width: '100%', mb: 2, p: 2 }}>
                {!openForm && !deleteModal && (
                    <ErrorAlert
                        style={{ marginBottom: '10px' }}
                        error={notification}
                        onClose={setNotification}
                        severity="success"
                    />
                )}
                <ErrorAlert
                    style={{ marginBottom: '10px' }}
                    error={err}
                    onClose={setErrMessage}
                    severity="error"
                />
                <Button
                    onClick={() =>
                        currentUser.role === 'admin'
                            ? setOpenForm(true)
                            : setErrMessage('No access to perform this action')
                    }
                    variant="contained"
                    color="primary"
                    style={{ marginBottom: '15px' }}
                    startIcon={<PersonAddAltOutlinedIcon />}
                >
                    Add Employee
                </Button>
                <TableContainer>
                    <Table sx={{ p: 2 }} aria-labelledby="tableTitle">
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={employees.length}
                        />
                        <TableBody>
                            {visibleRows.map((row, index) => {
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <>
                                        <TableRow
                                            tabIndex={-1}
                                            key={row.profile}
                                        >
                                            <TableCell align="left">
                                                {index + 1}
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                            >
                                                {row.name} {row.surname}
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                style={{
                                                    textTransform: 'capitalize',
                                                }}
                                            >
                                                {row.role}
                                            </TableCell>
                                            <TableCell align="right">
                                                {row.assignedJobsCount
                                                    ? row.assignedJobsCount
                                                    : 0}
                                            </TableCell>
                                            <TableCell align="right">
                                                {row.completedJobsCount
                                                    ? row.completedJobsCount
                                                    : 0}
                                            </TableCell>
                                            <TableCell align="right">
                                                <IconButton
                                                    aria-label="close"
                                                    color="inherit"
                                                    size="small"
                                                    onClick={() =>
                                                        currentUser.role ===
                                                        'admin'
                                                            ? editUserModalHandler(
                                                                  row
                                                              )
                                                            : setErrMessage(
                                                                  'No access to perform this action'
                                                              )
                                                    }
                                                >
                                                    <EditOutlinedIcon />
                                                </IconButton>
                                                <IconButton
                                                    aria-label="close"
                                                    color="inherit"
                                                    size="small"
                                                    onClick={() =>
                                                        currentUser.role ===
                                                        'admin'
                                                            ? confirmationDialogModalHandler(
                                                                  row
                                                              )
                                                            : setErrMessage(
                                                                  'No access to perform this action'
                                                              )
                                                    }
                                                >
                                                    <DeleteForeverOutlinedIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    </>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10]}
                    component="div"
                    count={employees.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
};

export default EmployeesScreen;
