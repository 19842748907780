/*
 ** @date: 12/07/2023
 ** @version: 1.0.0
 ** @author: argertboja@gmail.com
 ** @description: This component creates a Question - Action component.
                  Firstly the question is shown and if the user clicks on the action text an action is performed.
 ********************************
 ** @Expected Props:
 ******** props.question: String => Identification name of the input element
 ******** props.questionAction: Function => Function to perform when clicking on question text
 ******** props.questionIsAction: boolean => Flag to show pointer on question if question has an action
 ******** props.actionText: String => Identification name of the input element
 ******** props.action: Function => Function to perform when clicking on action text
 */
import React from 'react';
import { Typography, useTheme } from '@mui/material';

const QuestionActionText = (props) => {
    const theme = useTheme();
    return (
        <Typography
            fontSize={15}
            color={theme.palette.primary.main}
            fontStyle='italic'
        >
            <span
                style={{
                    color: '#3a4f4f',
                    cursor: props.questionIsAction ? 'pointer' : 'default',
                }}
                onClick={props.questionAction}
            >
                {props.question}{' '}
            </span>
            <span style={{ cursor: 'pointer' }} onClick={props.action}>
                {props.actionText}
            </span>
        </Typography>
    );
};

export default QuestionActionText;
