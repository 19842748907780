/*
 ** @date: 11/08/2023
 ** @version: 1.0.0
 ** @author: argertboja@gmail.com
 ** @description: Calendar Job View
 ********************************
 ** @Expected Props:
 ******** props.info: Object => Job object to fill data of view
 ** @Current version:
 ******** Logs user in
 ** @TODOs:
 ******** TODO:
 */
import React from 'react';

import { Avatar, Box } from '@mui/material';

// Icons
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

const CalendarJobView = (props) => {
    const job = props.info.event.extendedProps;
    const color =
        job.priority === 0
            ? '#048282'
            : job.priority === 1
            ? '#eba434'
            : '#941515'; //"#43515c";

    const styles = {
        jobContainer: {
            borderLeftColor: color,
            borderTopColor:
                job.assignedTo &&
                job.assignedTo[0] &&
                job.assignedTo[0].avatarColor,
            borderTopWidth: '5px',
            borderTopStyle: 'solid',
            borderRadius: '5px',
            height: '100%',
            borderLeftWidth: '5px',
            borderLeftStyle: 'solid',
        },
        jobTitle: {
            color: color,
            fontSize: 14,
            paddingLeft: 3,
        },
        infoContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
        },
        address: {
            color: 'black',
            display: 'flex',
            alignItems: 'center',
        },
        avatarContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
        },
        avatar: {
            width: 24,
            height: 24,
            mb: 0.5,
            mr: 0.5,
            ml: -1,
        },
    };
    return (
        <Box style={styles.jobContainer}>
            <Box style={styles.jobTitle}>{job.jobTitle}</Box>
            <Box
                style={{
                    display: 'flex',
                    row: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'flex-end',
                }}
            >
                <Box style={styles.infoContainer}>
                    <Box style={styles.address}>
                        <LocationOnOutlinedIcon sx={{ mr: 0.5 }} />
                        {job.address}
                    </Box>
                    <Box style={styles.address}>
                        <AccessTimeOutlinedIcon sx={{ mr: 0.5 }} />
                        {job.time}
                    </Box>
                </Box>
                <Box style={styles.avatarContainer}>
                    {job.assignedTo &&
                        job.assignedTo.map((user) => (
                            <Avatar
                                sx={{
                                    ...styles.avatar,
                                    bgcolor: user.avatarColor,
                                }}
                            >
                                {Array.from(user.name)[0] +
                                    Array.from(user.surname)[0]}
                            </Avatar>
                        ))}
                </Box>
            </Box>
        </Box>
    );
};

export default CalendarJobView;
