export function getInitials(firstName, lastName) {
    const initials = (firstName.charAt(0) + lastName.charAt(0)).toUpperCase();
    return initials;
}

export function formatStatus(status) {
    switch (status) {
        case 'new':
            return 'New';
        case 'completed':
            return 'Completed';
        // case 'invoiced':
        //     return 'Invoiced';
        // case 'canceled':
        //     return 'Canceled';
        // case 'partToOrder':
        //     return 'Part To Order';
        // case 'partOrdered':
        //     return 'Part Ordered';
        case 'partArrived':
            return 'Part Arrived';
        // case 'rescheduled':
        //     return 'Rescheduled';
        // case 'proposalSent':
        //     return 'Proposal Sent';
        // case 'proposalApproved':
        //     return 'Proposal Approved';
        // case 'proposalArrived':
        //     return 'Proposal Arrived';
        // case 'rentedOut':
        //     return 'Rented Out';
        // case 'pickUp':
        //     return 'Pick Up';
        default:
            return status;
    }
}
