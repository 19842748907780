/*
 ** @author: Argert Boja
 ** @date: 23/07/2023
 ** @version: 1.0.0
 ** @description: Component to show an error
 */

import React, { useEffect } from 'react';
import { Collapse, Alert, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ErrorAlert = (props) => {
    useEffect(() => {
        if (props.error !== null) {
            const timer = setTimeout(() => {
                props.onClose(null);
            }, 5000); // 5000 milliseconds = 5 seconds

            return () => {
                clearTimeout(timer); // Clear the timer if the component unmounts before 5 seconds
            };
        }
    }, [props, props.error, props.onClose]);

    return (
        <Collapse style={{ ...props.style }} in={props.error !== null}>
            <Alert
                severity={props.severity}
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            props.onClose(null);
                        }}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
            >
                {props.error}
            </Alert>
        </Collapse>
    );
};

export default ErrorAlert;
