/*
 ** @date: 02/02/2023
 */
import { createContext, useState, useMemo } from 'react';
import { createTheme } from '@mui/material/styles';

export const tokens = (mode) => ({
    ...(mode === 'dark'
        ? {
              primary: {
                  100: '#cde6e6',
                  200: '#9bcdcd',
                  300: '#68b4b4',
                  400: '#369b9b',
                  500: '#048282',
                  600: '#036868',
                  700: '#024e4e',
                  800: '#023434',
                  900: '#011a1a',
              },
              secondary: {
                  100: '#fbedd6',
                  200: '#f7dbae',
                  300: '#f3c885',
                  400: '#efb65d',
                  500: '#eba434',
                  600: '#bc832a',
                  700: '#8d621f',
                  800: '#5e4215',
                  900: '#2f210a',
              },
              tertiary: {
                  100: '#ead0d0',
                  200: '#d4a1a1',
                  300: '#bf7373',
                  400: '#a94444',
                  500: '#941515',
                  600: '#761111',
                  700: '#590d0d',
                  800: '#3b0808',
                  900: '#1e0404',
              },
          }
        : {
              primary: {
                  100: '#011a1a',
                  200: '#023434',
                  300: '#024e4e',
                  400: '#036868',
                  500: '#048282',
                  600: '#369b9b',
                  700: '#68b4b4',
                  800: '#9bcdcd',
                  900: '#cde6e6',
              },
              secondary: {
                  100: '#2f210a',
                  200: '#5e4215',
                  300: '#8d621f',
                  400: '#bc832a',
                  500: '#eba434',
                  600: '#efb65d',
                  700: '#f3c885',
                  800: '#f7dbae',
                  900: '#fbedd6',
              },
              tertiary: {
                  100: '#1e0404',
                  200: '#3b0808',
                  300: '#590d0d',
                  400: '#761111',
                  500: '#941515',
                  600: '#a94444',
                  700: '#bf7373',
                  800: '#d4a1a1',
                  900: '#ead0d0',
              },
          }),
});

// mui theme settings
export const themeSettings = (mode) => {
    const colors = tokens(mode);

    return {
        palette: {
            mode: mode,
            ...(mode === 'dark'
                ? {
                      primary: {
                          main: colors.primary[500],
                      },
                      secondary: {
                          main: colors.secondary[500],
                      },
                      tertiary: {
                          main: colors.tertiary[500],
                      },
                      background: {
                          default: colors.primary[900],
                      },
                  }
                : {
                      primary: {
                          main: colors.primary[500],
                      },
                      secondary: {
                          main: colors.secondary[500],
                      },
                      tertiary: {
                          main: colors.tertiary[500],
                      },
                      background: {
                          default: 'white',
                      },
                  }),
        },
        typography: {
            fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
            fontSize: 12,
            h1: {
                fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
                fontSize: 40,
            },
            h2: {
                fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
                fontSize: 32,
            },
            h3: {
                fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
                fontSize: 24,
            },
            h4: {
                fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
                fontSize: 20,
            },
            h5: {
                fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
                fontSize: 16,
            },
            h6: {
                fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
                fontSize: 14,
            },
        },
    };
};

// context for color mode
export const ColorModeContext = createContext({
    toggleColorMode: () => {},
});

export const useMode = () => {
    const [mode, setMode] = useState('light');

    const colorMode = useMemo(
        () => ({
            toggleColorMode: () =>
                setMode((prev) => (prev === 'light' ? 'dark' : 'light')),
        }),
        []
    );

    const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

    return [theme, colorMode];
};
