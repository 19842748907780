// 06/09/2023
import * as React from 'react';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Box, Button } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import JobListItem from './JobListItem';

const SearchList = (props) => {
    return (
        <>
            <Modal
                open={props.open}
                onClose={props.toggle}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        maxWidth: 600,
                        bgcolor: '#e8e9eb',
                        maxHeight: 600,
                        borderRadius: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        fontSize={30}
                        fontWeight="bold"
                        textAlign={'center'}
                        color="#048282"
                    >
                        Search Results
                    </Typography>
                    <List
                        sx={{
                            width: '100%',
                            maxWidth: 600,
                            bgcolor: '#e8e9eb',
                            maxHeight: 400,
                            position: 'relative',
                            overflow: 'auto',
                        }}
                    >
                        {props.results &&
                            props.results.map((job) => (
                                <JobListItem
                                    key={job._id}
                                    job={job}
                                    onClick={() => {
                                        props.onClick(job);
                                    }}
                                    dateAndTime
                                />
                            ))}
                    </List>
                    <Button
                        variant="contained"
                        color="error"
                        sx={{ mt: 1, mb: 1, width: '50%' }}
                        startIcon={<ClearIcon />}
                        onClick={props.toggle}
                    >
                        CLOSE
                    </Button>
                </Box>
            </Modal>
        </>
    );
};

export default SearchList;
