/*
 ** @date: 11/05/2023
 ** @version: 1.0.0
 ** @author: argertboja@gmail.com
 ** @description: This component creates a custom Date Time Picker
 ********************************
 ** @Expected Props:
 ******** props.name: String => Identification name of the input element
 ******** props.value: Object => Value of input field
 ******** props.onChange: Function => Function which stores the input value
 ******** props.handleTimePickerClose: Function => Function which closes the date time picker
 */
import React from 'react';
import dayjs from 'dayjs';
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';

const CustomDateTimePicker = ({
    name,
    value,
    onChange,
    handleTimePickerClose,
}) => {
    const handleChange = (newValue) => {
        // Pass name and value to the onChange function
        const event = {
            target: {
                name,
                value: newValue,
            },
        };

        // Pass the event object to the onChange function
        onChange(event);
    };

    return (
        <StaticDateTimePicker
            defaultValue={dayjs()}
            value={dayjs(value, 'YYYY-MM-DD HH:mm')}
            renderInput={(props) => <input {...props} />}
            onChange={handleChange}
            sx={{
                '& .MuiPickersSlideTransition-root': {
                    minHeight: '200px',
                },
            }}
            slotProps={{
                toolbar: {
                    hidden: true,
                },
            }}
            onClose={handleTimePickerClose}
        />
    );
};

export default CustomDateTimePicker;
